import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { until } from '@vueuse/core'
import { captureMessage } from '@sentry/vue'
import logger from '@shared/logger.js'
import { getRegisteredExperiments } from '@shared/api/experiments.js'
import { useRouter } from 'vue-router'
import getKeyedPromise from '@/utils/keyedPromise.js' // TODO: ENG-3647 - Isolate shared code.

export const useExperimentsStore = defineStore('experiments', () => {
  const experimentsLoaded = ref(false)
  const isAuthenticated = ref(false)
  /**
   * Dictionary of experiment metadata by name.
   * @type {import('vue').Ref<{ [k: string]: Partial<{
   *  variantNames: string[],
   *  showOriginalWhenInactive: boolean
   * }> }>}
   */
  const metadata = ref({})
  /**
   * Dictionary of experiment data by name.
   * @type {import('vue').Ref<{ [k: string]: Partial<{
   *  name: string,
   *  identifierType: string,
   *  variantName: string,
   *  isActive: boolean,
   *  isRegistered: boolean,
   * }> }>}
   */
  const experimentData = ref({})

  const experiments = computed(() => {
    const keys = [...Object.keys(metadata.value), ...Object.keys(experimentData.value)]
    return keys.reduce((acc, name) => {
      acc[name] = {
        ...metadata.value[name],
        ...experimentData.value[name]
      }
      return acc
    }, {})
  })

  const router = useRouter()
  router.beforeEach((to, from) => {
    // Normalize paths by removing trailing slashes.
    const [fromPath, toPath] = [from.path, to.path].map(path => path.replace(/\/$/, ''))

    // If we're navigating from the root path, we're likely refreshing the page.
    if (fromPath === toPath || fromPath === '') {
      return
    }

    const loginFilter = /\/login/
    const createAccountFilter = /\/create-account/
    const reset = loginFilter.test(fromPath) || loginFilter.test(toPath) || createAccountFilter.test(fromPath)
    if (reset) {
      resetExperiments()
    }
  })

  function updateMetadata (name, data) {
    if (typeof name !== 'string') {
      logger.error('Experiment name must be a string', name)
      return
    }
    metadata.value[name] = {
      ...metadata.value[name],
      ...data
    }
  }

  function isActiveExperiment (name) {
    return experiments.value[name]?.isActive === true
  }

  function isActiveVariant (name, variantName) {
    return isActiveExperiment(name) &&
      experiments.value[name]?.variantName === variantName
  }

  function isRegisteredExperiment (name) {
    return experiments.value[name]?.isRegistered === true
  }

  async function registerForExperiment (name) {
    if (typeof name !== 'string') {
      logger.error('Experiment name must be a string', name)
      return
    }
    if (isActiveExperiment(name) || experiments.value[name]?.isRegistered !== undefined) {
      return
    }
    experimentData.value[name] = {
      ...experimentData.value[name],
      isRegistered: false
    }

    // TODO: ENG-3647 - Isolate shared code.
    const { default: { registerExperiment } } = await import('@/store/api/apiClient.js') // Avoid circular dependency.
    const response = await registerExperiment({ name })
    if (response?.data) {
      updateExperiments(response.data)
    } else {
      captureMessage('Failed to register for experiment', {
        level: 'error',
        extra: { name, response }
      })
    }
  }

  function updateExperiments (updatedExperiments) {
    for (const experiment of updatedExperiments) {
      const { name, variantName } = experiment
      const currentExperiment = experiments.value[name]
      const isNewExperiment = !currentExperiment
      const variantChanged = currentExperiment && currentExperiment.variantName !== variantName
      if (isNewExperiment || variantChanged) {
        experimentData.value[name] = {
          ...experiment,
          isRegistered: true,
          isActive: true // The backend only sends active experiments.
        }
      }
    }
    experimentsLoaded.value = true
  }

  async function fetchRegisteredExperiments () {
    return getKeyedPromise('fetchRegisteredExperiments', async (resolve) => {
      const response = await getRegisteredExperiments()
      isAuthenticated.value = response.data.isAuthenticated === true
      updateExperiments(response.data.experiments)
      resolve()
    })
  }

  async function resetExperiments () {
    experimentsLoaded.value = false
    isAuthenticated.value = false
    experimentData.value = {}
    await fetchRegisteredExperiments()
  }

  async function untilExperimentsLoaded ({ timeout = 2000, throwOnTimeout = true } = {}) {
    if (!experimentsLoaded.value) {
      await Promise.all([
        fetchRegisteredExperiments().catch((error) => {
          if (throwOnTimeout) {
            throw error
          }
        }),
        until(experimentsLoaded).toBe(true, { timeout, throwOnTimeout })
      ])
    }
  }

  async function getAnalyticsData () {
    await untilExperimentsLoaded({ throwOnTimeout: false })
    return Object.keys(experiments.value).reduce((acc, name) => {
      const variant = experiments.value[name].variantName
      if (variant) {
        acc[`abTest.${name.replace(' ', '_')}`] = variant
      }
      return acc
    }, {})
  }

  return {
    experimentsLoaded,
    isAuthenticated,
    metadata,
    experimentData,
    experiments,
    updateMetadata,
    isActiveExperiment,
    isActiveVariant,
    isRegisteredExperiment,
    registerForExperiment,
    updateExperiments,
    fetchRegisteredExperiments,
    resetExperiments,
    untilExperimentsLoaded,
    getAnalyticsData
  }
})
