import { track } from '../../plugins/analytics'
import api from '../api/api'
import router from '../../router'
import { getSetters, parseError } from './helpers/shared'
import { captureMessage } from '@sentry/vue'
import logger from '@/logger'
import getKeyedPromise from '@/utils/keyedPromise.js'
import { until } from '@vueuse/core'

const util = {
  getSection: (state, predicate) => {
    return state.sections.find(predicate)
  },
  getSectionById: (state, sectionId) => {
    return util.getSection(state, section => section.id === sectionId)
  },
  getSectionBySource: (state, source) => {
    return util.getSection(state, section => section.source === source)
  }
}

// initial state
export const state = {
  sections: [],

  // Style Color pk -> Obj
  styleColorsMap: {},

  // Style Color pk -> rating (-1: dislike, 0: neutral, 1: favorite)
  ratingsMap: {},

  selectedItemTypes: [],
  unavailableItemTypes: [],

  styleColorSources: {},
  styleColorSourceIndexes: {},

  itemTypePricing: {},

  deliveredItems: [],
  mustReviewItems: [],
  awaitingReviewItems: [],
  packingItems: [],
  pendingItems: [],
  toReturnItems: [],
  transitItems: [],
  soldPackingItems: [],
  soldFutureItems: [],
  soldTransitItems: [],
  addOnPackingItems: [],
  addOnTransitItems: [],
  photoAlbumEntries: [],
  photoAlbumIndex: 0,

  selectedItemsLoaded: false,
  packageItemsLoaded: false,
  sectionsLoaded: false,
  featuredSectionsLoaded: false,
  myStuffSectionsLoaded: false,
  styleColorsLoading: false,
  selectedItemsMismatch: false,
  maxUnreviewedItems: null,

  globalFilterKeys: ['occasions', 'seasons', 'categories'],
  globalFilters: {},
  globalFiltersLoaded: false,
  baseFilters: {
    categories: [],
    occasions: [],
    seasons: [],
    brands: [],
    colors: [],
    patterns: [],
    fabrics: [],
    lengths: [],
    silhouettes: [],
    sleeveLengths: [],
    sleeveTypes: [],
    necklines: [],
    wash: [],
    rise: []
  },
  defaultSort: 'recommended',
  // browse / directed intent results
  browseStyleColors: {},
  browseSectionLoaded: false,
  browseSectionLoadingPage: false,
  browseSectionDetails: {},
  browseFilters: { categories: [], occasions: [], seasons: [], brands: [], colors: [], patterns: [], fabrics: [], lengths: [], silhouettes: [], sleeves: [], necklines: [], wash: [], rise: [] },
  browseFilterOptions: {},
  sale: null,
  sizeFilter: true
}

// from: https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d
// modified slightly for a 2nd key
const groupBy = function (data, key, key2) { // `data` is an array of objects, `key` is the key (or property accessor) to group by
  // reduce runs this anonymous function on each element of `data` (the `item` parameter,
  // returning the `storage` parameter at the end
  return data.reduce(function (storage, item) {
    // get the first instance of the key by which we're grouping
    const group = item[key][key2]

    // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
    storage[group] = storage[group] || []

    // add this item to its group within `storage`
    storage[group].push(item)

    // return the updated storage to the reduce function, which will then loop through the next
    return storage
  }, {}) // {} is the initial value of the storage
}

export const getters = {
  allSectionsLoaded: (state) => {
    return state.sectionsLoaded && state.featuredSectionsLoaded && state.myStuffSectionsLoaded
  },
  // Keep this in sync with the sections we load in loadInitialData.
  initialSectionsLoaded: (state) => {
    return state.sectionsLoaded && state.featuredSectionsLoaded
  },
  deliveredItemsByReturnDate: (state) => {
    return groupBy(state.deliveredItems, 'item', 'returnBy')
  },
  defaultSortFromSection: (state) => (section) => {
    if (section.source === 'autocloset') return undefined
    return state.defaultSort
  },
  getSectionPayload: (state, getters) => (section) => {
    const payload = {
      ...section,
      sort: getters.defaultSortFromSection(section),
      filters: getters.getSectionBaseFilters(section),
      detailView: {
        styleColors: null,
        length: null,
        sort: getters.defaultSortFromSection(section)
      }
    }
    if (section.source === 'favorites') {
      payload.available = true
      payload.detailView.available = true
    }
    return payload
  },
  closetSections: (state) => {
    return state.sections.filter(section => section.location === 'closet')
  },
  featuredSections: (state) => {
    return state.sections.filter(section => section.location === 'featured')
  },
  stylistSection: (state) => {
    return state.sections.find(section => section.source === 'stylist')
  },
  myStuffSections: (state) => {
    return state.sections.filter(section => section.location === 'my_stuff')
  },
  favoritesSection: (state) => {
    return util.getSectionBySource(state, 'favorites')
  },
  beTheBuyerSection: (state) => {
    return util.getSectionBySource(state, 'be_the_buyer')
  },
  selectedAvailableItemTypes: (state) => {
    const unavailableItemTypeIds = state.unavailableItemTypes.map(itemType => itemType.id)
    return state.selectedItemTypes.filter(itemType => unavailableItemTypeIds.indexOf(itemType.id) < 0)
  },
  styleColorSegmentData: (state) => (styleColorId) => {
    // Style color id, brand name, full name, category, subcategory, is favorite
    if (styleColorId in state.styleColorsMap) {
      const sc = state.styleColorsMap[styleColorId]
      return {
        id: styleColorId,
        brand: sc.style.brand,
        name: sc.style.name,
        category: sc.style.category,
        subcategory: sc.style.subcategory,
        isFavorite: state.ratingsMap[styleColorId]
      }
    } else {
      return {
        id: styleColorId,
        isFavorite: state.ratingsMap[styleColorId]
      }
    }
  },
  outstandingItems: (state) => {
    return [
      ...state.toReturnItems,
      ...state.transitItems,
      ...state.deliveredItems
    ]
  },
  unreviewedItemsByStyleColor: (state) => {
    const unreviewedByStyleColor = {}

    state.mustReviewItems.forEach(toReviewItem => {
      const sc = toReviewItem.item.styleColor
      if (unreviewedByStyleColor[sc]) {
        unreviewedByStyleColor[sc] = unreviewedByStyleColor[sc].concat(toReviewItem)
      } else {
        unreviewedByStyleColor[toReviewItem.styleColor] = [toReviewItem]
      }
    })

    state.deliveredItems.concat(state.toReturnItems).forEach(deliveredItem => {
      if (!deliveredItem.reviewed) {
        const sc = deliveredItem.item.styleColor
        if (unreviewedByStyleColor[sc]) {
          unreviewedByStyleColor[sc] = unreviewedByStyleColor[sc].concat(deliveredItem)
        } else {
          unreviewedByStyleColor[sc] = [deliveredItem]
        }
      }
    })

    return unreviewedByStyleColor
  },
  getSection: (state) => (predicate) => {
    return util.getSection(state, predicate)
  },
  getSectionById: (state) => (sectionId) => {
    return util.getSectionById(state, sectionId)
  },
  getSectionBySource: (state) => (source) => {
    return util.getSectionBySource(state, source)
  },
  getSectionIsGloballyFiltered: () => section => {
    return ['autocloset', 'style_prefs', 'brand_prefs'].includes(section.source)
  },
  getSectionBaseFilters: (state, getters) => (section) => {
    return getters.getSectionIsGloballyFiltered(section)
      ? { ...state.baseFilters, ...state.globalFilters.selections }
      : { ...state.baseFilters }
  },
  globallyFilteredSections: (state, getters) => {
    return state.sections?.filter(section => getters.getSectionIsGloballyFiltered(section))
  }
}

// actions
export const actions = {
  async getSale ({ commit }) {
    const res = await api.apiItems.getSale()
    commit('UPDATE_SALE', res.data)
  },
  clearAllFilters ({ dispatch, state }) {
    const filteredSections = state.sections.filter(section => {
      return section.available === false || (section.filters && Object.values(section.filters).reduce((a, b) => [...a, ...b]).length > 0)
    })
    filteredSections.forEach(async section => {
      await dispatch('resetFilters', section)
      dispatch('reloadSection', { id: section.id })
    })
  },
  clearFilters ({ commit, state, getters }, sectionId) {
    commit('UPDATE_SECTION', {
      section: getters.getSectionById(sectionId),
      filters: { ...state.baseFilters }
    })
  },
  async resetFilters ({ commit, getters }, section) {
    commit('UPDATE_SECTION', {
      section: getters.getSectionById(section.id),
      filters: getters.getSectionBaseFilters(section)
    })
  },
  resetDetailView ({ commit, getters }, sectionId) {
    const section = getters.getSectionById(sectionId)
    commit('UPDATE_SECTION', {
      section,
      useDetailView: true,
      styleColors: [...section.styleColors],
      sort: getters.defaultSortFromSection(section),
      available: section.detailView.available === undefined ? undefined : true,
      length: section.length
    })
  },
  async loadInitialData ({ commit, state, dispatch }) {
    const [styleProfile] = await Promise.all([
      dispatch('styleProfile/getStyleProfile', null, { root: true }),
      dispatch('getGlobalFilterOptions')
    ])
    // Otherwise, initialize the global filters from the style profile selections
    const filters = {}
    state.globalFilterKeys.forEach(key => {
      let translatedSelections = []
      const styleProfileKey = `selected${key.charAt(0).toUpperCase() + key.slice(1)}`
      translatedSelections = styleProfile[styleProfileKey].reduce((accruedSelections, styleProfileSelection) => {
        const closetFilterSelections = state.globalFilters.options[key].reduce((prevSelections, option) => {
          if (option.value === styleProfileSelection || option.value === 'occasion_' + styleProfileSelection) {
            return prevSelections.concat(option.value)
          }
          return prevSelections
        }, [])
        return accruedSelections.concat(closetFilterSelections)
      }, [])
      filters[key] = translatedSelections
    })
    commit('SET_GLOBAL_FILTER_SELECTIONS', filters)
    commit('SET_GLOBAL_FILTERS_LOADED', true)

    // Keep the sections we load here in sync with the initialSectionsLoaded getter.
    await dispatch('getSections')
    await dispatch('getFeaturedSections')

    dispatch('collections/getClientCollections', null, { root: true })
  },
  async getSections ({ commit, getters }) {
    commit('SET_SECTIONS_LOADED', false)
    const res = await api.apiCloset.getSections()
    const sections = res.data.sections.map(section => getters.getSectionPayload(section))
    commit('SET_SECTIONS', sections)
    commit('SET_SECTIONS_LOADED', true)
  },
  async getFeaturedSections ({ commit, getters }) {
    commit('SET_FEATURED_SECTIONS_LOADED', false)
    const res = await api.apiCloset.getSections('featured')
    const sections = res.data.sections.map(section => getters.getSectionPayload(section))
    commit('SET_FEATURED_SECTIONS', sections)
    commit('SET_FEATURED_SECTIONS_LOADED', true)
  },
  async getMyStuffSections ({ commit, getters }) {
    return getKeyedPromise('closet/getMyStuffSections', async (resolve) => {
      commit('SET_MY_STUFF_SECTIONS_LOADED', false)
      const res = await api.apiCloset.getSections('my_stuff')
      const sections = res.data.sections.map(section => getters.getSectionPayload(section))
      commit('SET_MY_STUFF_SECTIONS', sections)
      commit('SET_MY_STUFF_SECTIONS_LOADED', true)
      resolve()
    })
  },
  async getStyleColor ({ commit }, id) {
    try {
      logger.debug(id)
      const res = await api.apiCloset.getStyleColor(id)
      commit('UPDATE_STYLE_COLORS_MAP', [res.data])
    } catch (err) {
      if (err.message.includes('status code 404')) {
        // style color doesn't exist; either it was deleted/merged
        // or typo or something.  Either way, instead of an endless
        // placeholder, just redirect to the closet page.  We could
        // put a "sorry, couldn't find this style" or something msg,
        // but this is fine for now.
        router.push({ path: '/closet' })
      } else {
        throw err
      }
    }
  },
  async getClientSizingForStyleColor ({ commit, state }, id) {
    return getKeyedPromise(`closet/getClientSizingForStyleColor_${id}`, async (resolve) => {
      const styleColor = state.styleColorsMap[id]
      if (styleColor && (!('recommendedSize' in styleColor) || !('profileSizes' in styleColor))) {
        const res = await api.apiCloset.getClientSizingForStyleColor(id)
        commit('UPDATE_STYLE_COLOR', {
          styleColorId: id,
          data: {
            recommendedSize: res.data.recommendedSize,
            profileSizes: res.data.profileSizes
          }
        })
      }
      resolve()
    })
  },
  async getStyleColorsByBrowseSection ({ commit }, { params }) {
    commit('SET_BROWSE_SECTION_LOADED', false)
    commit('SET_BROWSE_STYLE_COLORS', {})
    commit('SET_BROWSE_SECTION_DETAILS', params)
    commit('SET_BROWSE_FILTERS', params.filters)
    const res = await api.apiCloset.getStyleColorsByBrowseSection(params)
    commit('SET_BROWSE_SECTION_LOADED', true)
    commit('SET_BROWSE_STYLE_COLORS', res.data)
  },
  async getBrowseSectionFilterOptions ({ commit }, { params }) {
    const res = await api.apiCloset.getBrowseSectionFilterOptions(params)
    commit('SET_BROWSE_FILTER_OPTIONS', res.data.filterOptions)
  },
  async getBrowseSectionsNextPage ({ commit, state }) {
    const nextPage = state.browseStyleColors.data.next
    const currentBrowseStyleColors = state.browseStyleColors.data

    if (nextPage) {
      commit('SET_BROWSE_SECTION_LOADING_PAGE', true)
      const res = await api.apiCloset.getBrowseSectionsNextPage(nextPage)

      commit('SET_BROWSE_STYLE_COLORS', {
        ...currentBrowseStyleColors,
        previous: res.data.previous,
        next: res.data.next,
        results: currentBrowseStyleColors.results.concat(res.data.results)
      })
      commit('SET_BROWSE_SECTION_LOADING_PAGE', false)
    }
  },
  async reloadSection ({ commit, dispatch }, { useDetailView, ...data }) {
    commit('RESET_SECTION_STYLE_COLORS', { sectionId: data.id, useDetailView })
    await dispatch('loadStyleColors', {
      id: data.id,
      amount: 100,
      useDetailView
    })
  },
  reloadAutoclosetSections ({ dispatch, state }) {
    const autoclosetSections = state.sections.filter(x => x.source === 'autocloset')
    autoclosetSections.forEach(section => dispatch('reloadSection', { id: section.id }))
  },
  async getSection ({ commit, getters, dispatch }, data) {
    const { id, maxItems } = data
    const res = await api.apiCloset.getSection(id, maxItems)
    if (maxItems) {
      res.data.length = maxItems
      res.data.maxItems = maxItems
    }
    commit('SET_SECTION', getters.getSectionPayload(res.data))
    commit('UPDATE_STYLE_COLORS_MAP', res.data.styleColors)

    const amountToLoad = Math.min(res.data.length ? res.data.length : 100, 100)

    dispatch('loadStyleColors', {
      id: id,
      amount: amountToLoad,
      useDetailView: true
    })
  },
  async loadStyleColors ({ commit, state, getters, dispatch }, { useDetailView, ...data }) {
    const key = `loadStyleColors_${data.id}${useDetailView ? '_detailView' : ''}`
    return getKeyedPromise(key, async (resolve) => {
      let section = getters.getSectionById(data.id)
      const loadStyleColorsRequestId = Math.random()
      if (typeof section === 'undefined') {
        captureMessage('loadStyleColors called with invalid section data', {
          level: 'error',
          extra: {
            section: data
          }
        })
        resolve()
        return
      }
      await until(() => section.styleColorsLoading === true).toBe(false)
      try {
        commit('UPDATE_SECTION', {
          section: section,
          loadStyleColorsRequestId: loadStyleColorsRequestId,
          styleColorsLoading: true
        })
        data.offset = useDetailView
          ? section.detailView.styleColors?.length || 0
          : section.styleColors?.length || 0
        data.available = useDetailView ? section.detailView.available : section.available
        data.sort = useDetailView ? section.detailView.sort : section.sort
        data.sectionFilters = section.filters
        data.sizeFilter = state.sizeFilter
        commit('SET_STYLE_COLORS_LOADING', true)
        const { data: resData } = await api.apiCloset.getSectionStyleColors(data)

        // Check that this is still the most recent load style colors request for section
        section = getters.getSectionById(data.id)
        if (loadStyleColorsRequestId === section.loadStyleColorsRequestId || !section.loadStyleColorsRequestId) {
          commit('ADD_STYLE_COLORS_TO_SECTION', {
            sectionId: data.id,
            styleColors: resData.styleColors,
            useDetailView
          })
          commit('UPDATE_SECTION', {
            section: section,
            length: resData.sectionLength,
            useDetailView
          })

          if (!('filterOptions' in section) || section.filterOptions === null) {
            if (getters.getSectionIsGloballyFiltered(section)) {
              dispatch('getFilterOptions', section)
            } else {
              commit('UPDATE_SECTION', {
                section: section,
                filterOptions: resData.filterOptions
              })
            }
          }
          commit('UPDATE_STYLE_COLORS_MAP', resData.styleColors)
          commit('SET_STYLE_COLORS_LOADING', false)
        }
        resolve()
      } finally {
        commit('UPDATE_SECTION', {
          section,
          styleColorsLoading: false
        })
      }
    })
  },
  async recordClick ({ state }, { styleColorId, source, sourceIndex }) {
    if (!source) {
      source = state.styleColorSources[styleColorId] ?? null
      sourceIndex = state.styleColorSourceIndexes[`${styleColorId}-${source?.id}`] ?? null
    }
    if (!source) {
      logger.warn('No style color source for click')
    }
    await api.apiCloset.recordClick(styleColorId, { source, sourceIndex })
  },
  // Fetch the base filter options for a globally-filtered section
  async getFilterOptions ({ commit, getters }, section) {
    const { data: { filterOptions } } = await api.apiCloset.getSectionStyleColors({ id: section.id, offset: 0, amount: 0 })
    commit('UPDATE_SECTION', {
      section: getters.getSectionById(section.id),
      filterOptions
    })
  },
  async getRatingsMap ({ commit }) {
    const res = await api.apiCloset.getRatingsMap()
    commit('SET_RATINGS', res.data)
  },
  async getFilteredReviews ({ commit }, { styleColorId, tagIds }) {
    const res = await api.apiCloset.getFilteredReviews(styleColorId, tagIds)
    commit('UPDATE_STYLE_COLOR', {
      styleColorId: styleColorId,
      data: {
        filteredReviews: res.data.filteredReviews,
        sortedReviews: [],
        unfilteredReviews: res.data.writtenReviews

      }
    })
  },
  async getSortedReviews ({ commit }, { styleColorId, sort }) {
    const res = await api.apiCloset.getReviews(styleColorId, sort)

    if (sort === 'similarity') {
      commit('UPDATE_STYLE_COLOR', {
        styleColorId: styleColorId,
        data: {
          filteredReviews: [],
          sortedReviews: [],
          unfilteredReviews: res.data.writtenReviews
        }
      })
    } else {
      commit('UPDATE_STYLE_COLOR', {
        styleColorId: styleColorId,
        data: {
          filteredReviews: [],
          sortedReviews: res.data.writtenReviews,
          unfilteredReviews: []
        }
      })
    }
  },
  async getReviews ({ commit, state, dispatch }, styleColorId) {
    let res = null
    const sc = state.styleColorsMap[styleColorId]
    if (sc.writtenReviews) {
      // already fetched; nothing to do
      return
    }
    // get the first page of reviews
    res = await api.apiCloset.getReviews(styleColorId)
    commit('UPDATE_STYLE_COLOR', {
      styleColorId: styleColorId,
      data: {
        totalReviewsCount: res.data.totalReviewsCount,
        clientReviews: res.data.clientReviews,
        expertReviews: res.data.expertReviews,
        imageReviews: res.data.imageReviews,
        writtenReviews: res.data.writtenReviews,
        unfilteredReviews: res.data.writtenReviews,
        avgStarRating: res.data.avgStarRating,
        numStarRatings: res.data.numStarRatings,
        avgSizingRating: res.data.avgSizingRating,
        numSizingRatings: res.data.numSizingRatings,
        sizingBiasRecommendation: res.data.sizingBiasRecommendation,
        sizingBiasSource: res.data.sizingBiasSource,
        filterOptions: res.data.filterOptions
      }
    })
    // then get any additional pages as needed.  This is a win for UX,
    // (near instant response when clicking on see all reviews)
    // but bad for the server.  We could consider waiting to fetch
    // addition pages until that link is clicked and putting a placeholder
    // while the data is fetched.
    while (res.data.writtenReviews?.next) {
      res = await api.apiClient.getNextPage(res.data.writtenReviews?.next)
      commit('APPEND_STYLE_COLOR_WRITTEN_REVIEWS', {
        styleColorId: styleColorId,
        data: res.data
      })
    }
    dispatch('getClientSizingForStyleColor', styleColorId)
  },
  async rateReview ({ commit }, { styleColorId, itemFeedbackId, rating }) {
    api.apiCloset.rateReview(itemFeedbackId, rating)
    commit('UPDATE_REVIEW_RATING', { styleColorId, itemFeedbackId, rating })
  },
  async getSelected ({ commit }) {
    const res = await api.apiCloset.getSelected()
    commit('SET_SELECTED_ITEM_TYPES', res.data.selectedItemTypes)
    commit('SET_UNAVAILABLE_ITEM_TYPES', res.data.unavailableItemTypes)
    commit('UPDATE_STYLE_COLORS_MAP', res.data.styleColors)
    commit('SET_SELECTED_ITEMS_LOADED', true)
  },
  async getPackageItems ({ commit }) {
    const res = await api.apiCloset.getPackageItems()
    commit('SET_SOLD_PACKING_ITEMS', res.data.soldItems.packing)
    commit('SET_SOLD_TRANSIT_ITEMS', res.data.soldItems.transit)
    commit('SET_SOLD_FUTURE_ITEMS', res.data.soldItems.future)
    commit('SET_DELIVERED_ITEMS', res.data.deliveredItems)
    commit('SET_MUST_REVIEW_ITEMS', res.data.mustReviewItems)
    commit('SET_AWAITING_REVIEW_ITEMS', res.data.awaitingReviewItems)
    commit('SET_PACKING_ITEMS', res.data.packingItems)
    commit('SET_PENDING_ITEMS', res.data.pendingItems)
    commit('SET_TO_RETURN_ITEMS', res.data.toReturnItems)
    commit('SET_TRANSIT_ITEMS', res.data.transitItems)
    commit('SET_MAX_UNREVIEWED_ITEMS', res.data.maxUnreviewedItems)
    commit('UPDATE_STYLE_COLORS_MAP', res.data.styleColors)
    commit('SET_ADD_ON_PACKING_ITEMS', res.data.addOnItems.packing)
    commit('SET_ADD_ON_TRANSIT_ITEMS', res.data.addOnItems.transit)
    commit('SET_PACKAGE_ITEMS_LOADED', true)
  },
  async select ({ commit, state, dispatch }, itemType) {
    try {
      commit('SELECT', itemType)

      // source is a string that tells us where the item being added to case is from
      // event_source is used here until mobile gets updated
      const source = state.styleColorSources[itemType.styleColor] ?? null
      await api.apiCloset.setClosetItemSelected({
        itemtype_id: itemType.id,
        selected: true,
        event_source: source,
        source
      })
      dispatch('case/addToCase', null, { root: true })
    } catch (err) {
      commit('DESELECT', itemType)
      throw err
    }
  },
  async deselect ({ commit, state, rootGetters, dispatch }, itemType) {
    try {
      commit('DESELECT', itemType)

      // source is a string that tells us where the item being removed from case is from
      const source = state.styleColorSources[itemType.styleColor] ?? null

      await api.apiCloset.setClosetItemSelected({
        itemtype_id: itemType.id,
        selected: false,
        event_source: source,
        source
      })
      // if it's a non-rental plan, then deselecting an item
      // during case confirmation will invalidate the price
      if (!rootGetters['client/hasRentalPlan']) {
        commit('case/SET_CASE_PRICE', null, { root: true })
        dispatch('case/getCasePrice', null, { root: true })
      }
    } catch (err) {
      commit('SELECT', itemType)
      throw err
    }
  },
  deselectUnavailableItemTypes ({ commit, state, dispatch }) {
    state.unavailableItemTypes.forEach(itemType => {
      dispatch('deselect', itemType)
    })
    commit('SET_UNAVAILABLE_ITEM_TYPES', [])
  },
  async favorite ({ commit, state, dispatch, getters }, { styleColorId }) {
    try {
      commit('FAVORITE', styleColorId)
      if (!(styleColorId in state.styleColorsMap)) {
        // if we don't already have the style color data go get it
        await dispatch('getStyleColor', styleColorId)
      }

      // source is a string that tells us where the item was favorited from
      // The handling for like/dislike/favorite/unfavorite on the backend does "decoding",
      const source = state.styleColorSources[styleColorId] ?? null
      const res = await api.apiClient.setStyleColorRating({
        style_color_id: styleColorId,
        liked: 1,
        source
      })
      await dispatch('styleColorRatingFollowup', { likesAndFavorites: res.data, source })
      if (getters.favoritesSection) {
        await dispatch('refreshFavoritesSection')
        await dispatch('resetDetailView', getters.favoritesSection.id)
      }
    } catch (err) {
      commit('UNFAVORITE', styleColorId)
      throw err
    }
  },
  async refreshFavoritesSection ({ commit, getters }) {
    const favoritesId = getters.favoritesSection.id
    const res = await api.apiCloset.getSectionStyleColors({
      id: favoritesId,
      available: true,
      offset: 0,
      amount: 20
    })
    commit('UPDATE_SECTION', {
      section: getters.getSectionById(getters.favoritesSection.id),
      styleColors: res.data.styleColors.map(sc => sc.id),
      length: res.data.sectionLength,
      filterOptions: res.data.filterOptions
    })
    commit('UPDATE_STYLE_COLORS_MAP', res.data.styleColors)
  },
  async removeStyleColorFromFavoritesSection ({ commit, getters }, styleColorId) {
    try {
      if (getters.favoritesSection) {
        commit('REMOVE_STYLE_COLORS_FROM_SECTION', {
          sectionId: getters.favoritesSection.id,
          styleColors: [{ id: styleColorId }]
        })
      }
    } catch (err) {
      commit('FAVORITE', styleColorId)
      throw err
    }
  },
  styleColorRatingFollowup ({ commit, rootGetters }, { likesAndFavorites, source }) {
    if (source?.sourceType === 'feeditem') {
      if (source.sourceId) {
        const feedItemId = source.sourceId
        commit('community/UPDATE_LIKES_AND_FAVORITES', { feedItemId, data: likesAndFavorites }, { root: true })
        commit('community/UPDATE_FIRST_INTERACTION_CLIENT', {
          feedItemId,
          clientProfile: rootGetters['community/clientProfile']
        }, { root: true })
      }
    }
  },
  async unfavorite ({ commit, state, dispatch }, { styleColorId }) {
    try {
      commit('UNFAVORITE', styleColorId)
      if (!(styleColorId in state.styleColorsMap)) {
        // if we don't already have the style color data go get it
        await dispatch('getStyleColor', styleColorId)
      }

      // source is a string that tells us where the item was unfavorited from
      // The handling for like/disklike/favorite/unfavorite on the backend does "decoding",
      const source = state.styleColorSources[styleColorId] ?? null

      const res = await api.apiClient.setStyleColorRating({
        style_color_id: styleColorId,
        liked: 0,
        source
      })
      // remove from My Favorites section
      await dispatch('removeStyleColorFromFavoritesSection', styleColorId)
      await dispatch('styleColorRatingFollowup', { likesAndFavorites: res.data, source })
    } catch (err) {
      commit('FAVORITE', styleColorId)
      throw err
    }
  },
  async dislike ({ commit, state, dispatch }, { styleColorId }) {
    try {
      commit('DISLIKE', styleColorId)
      if (!(styleColorId in state.styleColorsMap)) {
        // if we don't already have the style color data go get it
        await dispatch('getStyleColor', styleColorId)
      }

      // source is a string that tells us where the item was selected from
      // The handling for like/disklike/favorite/unfavorite on the backend does "decoding",
      const source = state.styleColorSources[styleColorId]

      const res = await api.apiClient.setStyleColorRating({
        style_color_id: styleColorId,
        liked: -1,
        source,
        index: state.styleColorSourceIndexes[styleColorId + '-' + source]
      })
      // remove from My Favorites section
      dispatch('removeStyleColorFromFavoritesSection', styleColorId)
      dispatch('styleColorRatingFollowup', { likesAndFavorites: res.data, source })
    } catch (err) {
      commit('UNDISLIKE', styleColorId)
      throw err
    }
  },
  async undislike ({ commit, state, dispatch }, { styleColorId }) {
    try {
      commit('UNDISLIKE', styleColorId)
      if (!(styleColorId in state.styleColorsMap)) {
        // if we don't already have the style color data go get it
        await dispatch('getStyleColor', styleColorId)
      }

      // source is a string that tells us where the item was selected from
      // The handling for like/disklike/favorite/unfavorite on the backend does "decoding",
      const source = state.styleColorSources[styleColorId]

      await api.apiClient.setStyleColorRating({
        style_color_id: styleColorId,
        liked: 0,
        source: source,
        index: state.styleColorSourceIndexes[styleColorId + '-' + source]
      })
    } catch (err) {
      commit('DISLIKE', styleColorId)
      throw err
    }
  },
  async requestStylist ({ commit }, formData) {
    const res = await api.apiCloset.requestStylist(formData)
    commit('client/UPDATE_CLIENT', res.data.client, { root: true })
  },
  async getPriceRange ({ commit, state }, data) {
    const styleColor = state.styleColorsMap[data.styleColorId]
    const hasPriceRange = styleColor?.priceRange &&
      styleColor.priceRange.minPrice !== null &&
      styleColor.priceRange.maxPrice !== null
    if (!hasPriceRange) {
      const res = await api.apiCloset.getPriceRange(data)
      commit('UPDATE_STYLE_COLOR', {
        styleColorId: data.styleColorId,
        data: {
          priceRange: res.data
        }
      })
    }
  },
  async checkForSelectedItemsMismatch ({ commit, dispatch, state }) {
    commit('SET_SELECTED_ITEMS_MISMATCH', false)
    const currentClientSelectedItemTypeIds = state.selectedItemTypes.map(itemType => itemType.id)
    await dispatch('getSelected')
    const currentServerSelectedItemTypeIds = state.selectedItemTypes.map(itemType => itemType.id)
    if (currentClientSelectedItemTypeIds.length !== currentServerSelectedItemTypeIds.length ||
      !currentClientSelectedItemTypeIds.every(id => currentServerSelectedItemTypeIds.indexOf(id) > -1)) {
      commit('SET_SELECTED_ITEMS_MISMATCH', true)
    }
  },
  async submitCloset ({ commit, dispatch, state, rootGetters, rootState }, data) {
    try {
      if (typeof (data) === 'undefined') {
        data = {}
      }

      let res = {}
      // this flag means purchase additional bonus items, IF
      // needed (i.e., member is submitting more items than
      // their plan + existing bonus items cover)
      data.outbound_shipping_id = rootState.case.selectedShippingId
      data.selected_item_types = state.selectedItemTypes.map(itemType => itemType.id)

      if (rootGetters['client/hasRentalPlan']) {
        data.purchase_bonus_items = true
        data.expedited_return = rootState.case.expeditedReturnSelected
        if (rootState.case.addOnItemsToPurchase.length > 0) {
          data.addOnItemTypes = rootState.case.addOnItemsToPurchase.map(x => {
            return {
              id: x.addOnTypeId,
              quantity: x.quantity
            }
          })
        }
        if (rootState.case.purchaseSuggestionsInCase.length > 0) {
          data.purchase_items = rootState.case.purchaseSuggestionsInCase
        }
        res = await api.apiCloset.submitCloset(data)
        if (res.data.status === 'submitted') {
          commit('client/UPDATE_CLIENT', res.data.client, { root: true })
          commit('case/SET_PURCHASED_ADD_ON_ITEMS', [...rootState.case.addOnItemsToPurchase], { root: true })
          dispatch('case/handleCaseBackups', res.data, { root: true })
          dispatch('case/setMyCaseFlyoutContext', { confirmedPackageId: res.data.package.id }, { root: true })
          dispatch('getSelected')
          dispatch('getPackageItems')
          return true
        }
      } else {
        res = await dispatch('client/purchaseCase', data, { root: true })
        dispatch('case/setMyCaseFlyoutContext', { confirmedPackageId: res.data.package.id }, { root: true })
        dispatch('getSelected')
        dispatch('getPackageItems')
        return true
      }
    } catch (err) {
      // unavailable items we unselect + return info to user so they can choose new ones
      if (err.response && err.response.data && err.response.data.data && err.response.data.data.unavailableItemTypes) {
        const unavailableItemTypes = err.response.data.data.unavailableItemTypes
        commit('SET_UNAVAILABLE_ITEM_TYPES', unavailableItemTypes)

        unavailableItemTypes.forEach(itemType => {
          commit('DESELECT', itemType)
        })
        return false
      }
      // mismatch between client and server selected items
      // get the server's selected items so client can verify those before submitting
      if (err.response && err.response.data && err.response.data.errorCode === 209) {
        await dispatch('checkForSelectedItemsMismatch')
        return false
      }
      // otherwise, parse and show error
      throw parseError(err)
    }
  },
  async getLooksByStyleColor ({ commit }, styleColorId) {
    const res = await api.apiCloset.getLooksByStyleColor(styleColorId)
    commit('UPDATE_STYLE_COLOR', {
      styleColorId: styleColorId,
      data: {
        looks: res.data.results
      }
    })
  },
  async getSimilarStyleColors ({ commit }, styleColorId) {
    const res = await api.apiCloset.getSimilarStyleColors(styleColorId)
    commit('UPDATE_STYLE_COLORS_MAP', res.data)
    commit('UPDATE_STYLE_COLOR', {
      styleColorId: styleColorId,
      data: {
        similar: res.data.map(sc => sc.id)
      }
    })
  },
  async getAdditionalColors ({ commit }, styleColorId) {
    const res = await api.apiCloset.getAdditionalColors(styleColorId)
    commit('UPDATE_STYLE_COLORS_MAP', res.data)
    commit('UPDATE_STYLE_COLOR', {
      styleColorId: styleColorId,
      data: {
        additionalColors: res.data.map(sc => sc.id)
      }
    })
  },
  async getGlobalFilterOptions ({ commit }) {
    const res = await api.apiCloset.getGlobalClosetFilterOptions()
    commit('SET_GLOBAL_FILTER_OPTIONS', res.data)
  },
  setStyleColorSource ({ commit }, data) {
    commit('SET_STYLE_COLOR_SOURCE', data)
  },
  updateSection ({ commit, getters }, data) {
    data.section = getters.getSectionById(data.id)
    commit('UPDATE_SECTION', data)
  },
  updateSectionFilters ({ commit }, data) {
    commit('UPDATE_SECTION_FILTERS', data)
  },
  async resetGlobalFilters ({ state, getters, commit, dispatch }) {
    const res = await api.apiStyleProfile.resetGlobalFilters()
    const filters = {
      categories: res.data.selectedCategories,
      occasions: res.data.selectedOccasions,
      seasons: res.data.selectedSeasons
    }
    commit('SET_GLOBAL_FILTER_SELECTIONS', filters)
    getters.globallyFilteredSections?.forEach(async section => {
      commit('UPDATE_SECTION', {
        section: getters.getSectionById(section.id),
        filters: { ...state.baseFilters, ...filters },
        styleColors: null,
        length: null
      })
      dispatch('resetDetailView', section.id)
    })
  },
  applyGlobalFilters ({ state, getters, commit, dispatch }, filters) {
    commit('SET_GLOBAL_FILTER_SELECTIONS', filters)
    getters.globallyFilteredSections?.forEach(async section => {
      commit('UPDATE_SECTION', {
        section: getters.getSectionById(section.id),
        filters: { ...state.baseFilters, ...filters },
        styleColors: null,
        length: null
      })
      await dispatch('reloadSection', {
        id: section.id,
        amount: 20
      })
      dispatch('resetDetailView', section.id)
    })
    dispatch('styleProfile/saveGlobalFilters', filters, { root: true })

    track('Applied Full Closet Filters', filters)
  },
  async reloadSections ({ commit, dispatch }) {
    commit('SET_SECTIONS', [])
    await dispatch('getSections')
  }
}

// mutations
export const mutations = {
  ...getSetters(state),
  'SET_SECTION' (state, section) {
    const index = state.sections.findIndex(x => x.id === section.id)
    if (index > -1) {
      state.sections[index] = section
    } else {
      state.sections.push(section)
    }
  },
  'SET_SECTIONS' (state, sections) {
    state.sections = state.sections.filter(section => section.location !== 'closet').concat(sections)
  },
  'SET_FEATURED_SECTIONS' (state, sections) {
    state.sections = state.sections.filter(section => section.location !== 'featured').concat(sections)
  },
  'SET_MY_STUFF_SECTIONS' (state, sections) {
    state.sections = state.sections.filter(section => section.location !== 'my_stuff').concat(sections)
  },
  'UPDATE_SECTION' (state, { useDetailView, ...data }) {
    if (typeof data.section === 'undefined') {
      captureMessage('UPDATE_SECTION called with invalid section data', {
        level: 'error',
        extra: { data }
      })
      return
    }

    const { section, ...properties } = data

    if (section) {
      // First time, we update both the detail view and the section view
      const objectsToUpdate = []
      if (section.length === null) {
        objectsToUpdate.push(section.detailView)
        objectsToUpdate.push(section)
      } else if (useDetailView) {
        objectsToUpdate.push(section.detailView)
      } else {
        objectsToUpdate.push(section)
      }
      objectsToUpdate.forEach(objectToUpdate => {
        Object.keys(properties).forEach(key => {
          objectToUpdate[key] = data[key]
        })
      })
    } else {
      logger.warn('Section not found, adding new section', data)
      state.sections.push(data)
    }
  },
  'UPDATE_SECTION_FILTERS' (state, data) {
    const section = util.getSectionById(state, data.section.id)
    section.filters[data.key] = data.value
  },
  'INCREMENT_SECTION_LENGTH' (state, sectionId) {
    const section = util.getSectionById(state, sectionId)
    if (section) section.length = section.length + 1
  },
  'APPEND_STYLE_COLOR_WRITTEN_REVIEWS' (state, { styleColorId, data }) {
    if (state.styleColorsMap[styleColorId]?.writtenReviews) {
      if (data?.writtenReviews) {
        state.styleColorsMap[styleColorId].writtenReviews.results = state.styleColorsMap[styleColorId].writtenReviews.results.concat(data.writtenReviews.results)
      } else {
        logger.warn('No written reviews in data ', data)
      }
    } else {
      logger.warn('No written reviews found for style color ', styleColorId)
    }
  },
  'UPDATE_STYLE_COLOR' (state, data) {
    state.styleColorsMap[data.styleColorId] = {
      ...state.styleColorsMap[data.styleColorId],
      ...data.data
    }
  },
  'SET_BROWSE_STYLE_COLORS' (state, data) {
    state.browseStyleColors = { data }
  },
  'UPDATE_STYLE_COLORS_MAP' (state, styleColors) {
    if (styleColors) {
      for (const styleColor of styleColors) {
        if (!state.styleColorsMap[styleColor.id]) {
          state.styleColorsMap[styleColor.id] = styleColor
        }
      }
    }
  },
  'ADD_STYLE_COLORS_TO_SECTION' (state, { sectionId, styleColors, prepend = false, useDetailView = false }) {
    const selectedSection = util.getSectionById(state, sectionId)
    if (!selectedSection) {
      return
    }

    // First time, we update both the detail view and the section view
    const objectsToUpdate = []
    if (!selectedSection.styleColors || !selectedSection.styleColors.length) {
      objectsToUpdate.push(selectedSection.detailView)
      objectsToUpdate.push(selectedSection)
    } else if (useDetailView) {
      objectsToUpdate.push(selectedSection.detailView)
    } else {
      objectsToUpdate.push(selectedSection)
    }

    for (const obj of objectsToUpdate) {
      if (!obj.styleColors) {
        obj.styleColors = []
      }
      const styleColorIds = styleColors.map(x => x.id)
      if (prepend) {
        obj.styleColors.unshift(...styleColorIds)
      } else {
        obj.styleColors.push(...styleColorIds)
      }
    }
  },
  'REMOVE_STYLE_COLORS_FROM_SECTION' (state, { sectionId, styleColors }) {
    const section = util.getSectionById(state, sectionId)

    if (section) {
      const sectionStyleColorIds = section.styleColors
      const detailStyleColorIds = section.detailView?.styleColors

      const styleColorIds = styleColors.map(styleColor => styleColor.id)
      styleColorIds.forEach(styleColorId => {
        const index = sectionStyleColorIds.indexOf(styleColorId)
        if (index > -1) section.styleColors.splice(index, 1)
        section.length--

        // also remove from detail view if we find it
        if (detailStyleColorIds) {
          const detailIndex = detailStyleColorIds.indexOf(styleColorId)
          if (detailIndex > -1) {
            section.detailView.styleColors.splice(detailIndex, 1)
            section.detailView.length--
          }
        }
      })
    }
  },
  'SELECT' (state, itemType) {
    state.selectedItemTypes.push(itemType)
  },
  'DESELECT' (state, itemType) {
    const index = state.selectedItemTypes.map(itemType => itemType.id).indexOf(itemType.id)
    if (index > -1) {
      state.selectedItemTypes.splice(index, 1)
    }
  },
  'ITEM_PURCHASED' (state, packageItemId) {
    let index = state.deliveredItems.map(item => item.id).indexOf(packageItemId)
    if (index > -1) {
      state.deliveredItems.splice(index, 1)
    } else {
      // Can now also purchase from returning items
      index = state.toReturnItems.map(item => item.id).indexOf(packageItemId)
      if (index > -1) {
        state.toReturnItems.splice(index, 1)
      }
    }
  },
  'SET_RATINGS' (state, data) {
    state.ratingsMap = { ...state.ratingsMap, ...data }
  },
  'SET_RATING' (state, data) {
    state.ratingsMap[data.styleColorId] = data.value
  },
  'FAVORITE' (state, styleColorId) {
    state.ratingsMap[styleColorId] = 1
  },
  'UNFAVORITE' (state, styleColorId) {
    state.ratingsMap[styleColorId] = 0
  },
  'DISLIKE' (state, styleColorId) {
    state.ratingsMap[styleColorId] = -1
  },
  'UNDISLIKE' (state, styleColorId) {
    state.ratingsMap[styleColorId] = 0
  },
  'UPDATE_PRICE_RANGE' (state, data) {
    state.styleColorsMap[data.styleColorPk].priceRange = data.priceRange
  },
  'SET_ITEM_TYPE_PRICE' (state, data) {
    state.itemTypePricing[data.itemTypeId] = data.value
  },
  'UPDATE_SALE' (state, data) {
    state.sale = data
  },
  'RESET_SECTION_STYLE_COLORS' (state, { sectionId, useDetailView }) {
    const section = util.getSectionById(state, sectionId)
    if (section) {
      const objectToUpdate = useDetailView ? section.detailView : section
      objectToUpdate.length = null
      objectToUpdate.styleColors = []
    }
  },
  'SET_PHOTO_ALBUM_ENTRIES' (state, photoAlbumEntries) {
    state.photoAlbumEntries = photoAlbumEntries
  },
  'SET_PHOTO_ALBUM_INDEX' (state, index) {
    state.photoAlbumIndex = index
  },
  'SET_STYLE_COLOR_SOURCE' (state, data) {
    state.styleColorSources[data.styleColorId] = data.source
    state.styleColorSourceIndexes[data.styleColorId + '-' + data.source.sourceId] = data.sourceIndex
  },
  'UPDATE_REVIEW_RATING' (state, { styleColorId, itemFeedbackId, rating }) {
    const writtenReviews = state.styleColorsMap[styleColorId]?.writtenReviews
    if (writtenReviews?.results) {
      const reviewIndex = writtenReviews.results.findIndex(review => review.id === itemFeedbackId)
      const review = writtenReviews.results[reviewIndex]
      writtenReviews.results[reviewIndex] = {
        ...review,
        memberFoundHelpful: rating === 1,
        membersHelped: review.membersHelped + (review.memberFoundHelpful ? -1 : 1)
      }
    } else {
      logger.warn('No written reviews or results found for style color ', styleColorId)
    }
  },
  'SET_GLOBAL_FILTER_OPTIONS' (state, data) {
    state.globalFilters.options = data
  },
  'SET_GLOBAL_FILTER_SELECTIONS' (state, data) {
    state.globalFilters.selections = data
  },
  'RESET_GLOBAL_FILTER_SELECTIONS' (state) {
    state.globalFilters.selections = {}
    for (const k of state.globalFilterKeys) {
      state.globalFilters.selections[k] = []
    }
  }
}
