<template>
  <div>
    <sign-up-promo-banner v-if="$route.meta.hideNavBar && !inMobileWebView()"/>
    <div class="text-center h-100">
      <div
        class="router-box"
        :class="{'mt-5': !showPromoBanner}">
        <router-view
          :key="$route.path"
          #default="{ Component }">
          <transition
            name="fade"
            mode="out-in">
            <component
              :is="Component"
              class="mx-auto pb-5"
              :class="{'container': $route.name.startsWith('style-profile'), 'pt-4 pt-md-5': showPromoBanner || showReferralBanner}"/>
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import SignUpPromoBanner from './SignUpPromoBanner'
import AnalyticsMixin from '../global/mixins/analyticsMixin'
import { mapActions, mapGetters, mapState } from 'vuex'
import { vuexAccessors } from '../global/helpers/vuex'
import { inMobileWebView } from '@/utils/mobileApp.js'

export default {
  components: {
    SignUpPromoBanner
  },
  mixins: [AnalyticsMixin],
  computed: {
    ...mapState('client', [
      'membershipStatus',
      'isAuthenticated'
    ]),
    ...mapState('subscribe', [
      'membershipPlans'
    ]),
    ...mapGetters('subscribe', [
      'membershipPlanSelected'
    ]),
    ...mapState('styleQuizNav', [
      'isPrepayPlan'
    ]),
    ...mapGetters('subscribe', [
      'membershipPlanSelected',
      'showPromoBanner',
      'showReferralBanner'
    ]),
    ...vuexAccessors('styleQuizNav', [
      'isRejoiningMember'
    ])
  },
  watch: {
    $route: function () {
      this.sendMembershipAnalytics()
    }
  },
  async mounted () {
    this.sendMembershipAnalytics()
    this.getReferralCampaign()
    await this.parseQueryParams({ query: this.$route.query })
    await this.fetchPlans()
    if (this.isAuthenticated) {
      await this.getClient()
    }
    if (!this.membershipPlanSelected && !this.isPrepayPlan) {
      const defaultPlan = this.membershipPlans.find(plan => plan.name === 'Armoire Unlimited Monthly')
      await this.selectMembershipPlan({ planId: defaultPlan.id })
    }

    this.isRejoiningMember = this.membershipStatus === 'former_member'
  },
  methods: {
    inMobileWebView,
    ...mapActions('subscribe', [
      'getReferralCampaign',
      'selectMembershipPlan',
      'parseQueryParams',
      'fetchPlans'
    ]),
    ...mapActions('client', [
      'getClient'
    ])
  }
}
</script>

<style lang="scss">
  .router-box {
    min-height: 650px
  }

  .fade-enter-active, .fade-leave-active {
    transition-property: opacity;
    transition-duration: 0.3s;
  }

  .fade-enter-active {
    transition-delay: 0.3s;
  }

  .fade-enter-from, .fade-leave-active {
    opacity: 0
  }
</style>
