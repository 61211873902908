import { computed, toValue } from 'vue'
import { useRouter } from 'vue-router'

/**
 * Composable to determine the type of link to use based on the input.
 * @param {import('vue-router').RouteLocation | string} to
 * @param {object} [options]
 * @param {string} options.fallbackType The type of element to use if the link is not a RouterLink.
 */
export default function useLink (to, options = { fallbackType: 'a' }) {
  const router = useRouter()

  const isExternalLink = computed(() => /^https?:\/\//.test(toValue(to)))
  const isRouterLink = computed(() => {
    const value = toValue(to)
    if (!value || isExternalLink.value) {
      return false
    }
    try {
      const { matched: [{ redirect }] } = router.resolve(toValue(to))
      return redirect !== '/404'
    } catch (e) {
      return false
    }
  })
  /** Local to this site, but not to this router */
  const isLocalLink = computed(() => !isRouterLink.value && /^\//.test(toValue(to)))
  const isHyperlink = computed(() => isExternalLink.value || isLocalLink.value)

  const type = computed(() => isRouterLink.value ? 'RouterLink' : isHyperlink.value ? 'a' : options.fallbackType)
  const linkParams = computed(() =>
    isRouterLink.value
      ? { to: toValue(to) }
      : isHyperlink.value
        ? isExternalLink.value
          ? { href: toValue(to), target: '_blank' }
          : { href: toValue(to) }
        : null
  )

  function activate () {
    const value = toValue(to)
    if (isRouterLink.value) {
      router.push(value)
    } else if (isHyperlink.value) {
      if (isExternalLink.value) {
        window.open(value, '_blank')
      } else {
        window.location.href = value
      }
    }
  }

  return {
    isExternalLink,
    isRouterLink,
    isLocalLink,
    isHyperlink,
    type,
    linkParams,
    activate
  }
}
