<template>
  <accordion-with-header
    :expanded="expanded"
    :expanded-max-height="800"
    title="Free return pickups"
    @accordion-click="e => $emit('accordion-click', e)">
    <div class="mt-2">
      <h5 fontWeight="semibold">
        All locations
      </h5>
      <p>
        You can <text-link
          size="paragraph"
          type="a"
          :href="USPS_PICKUP_URL">
          schedule a free <strong>USPS</strong> returns pickup
        </text-link> from anywhere in the U.S.
      </p>
      <div
        v-if="goPickupLocation">
        <h5>
          {{ goPickupLocation.city }}
        </h5>
        <p>
          We offer Return drop offs and Case pickups at our boutique, <strong>{{ goPickupLocation.name }}</strong>, located at {{ goPickupLocation.addressLine1 }}.
        </p>
        <div
          v-if="goPickupLocation.timings">
          <p>
            It is open:
            <ul
              class="mt-1">
              <li
                v-for="(timing, key) in goPickupLocation.timings"
                :key="key">
                {{ timing }}
              </li>
            </ul>
          </p>
        </div>
        <p>
          If you choose to have your Case delivered with our Seattle-area same day
          shipping partner, <strong>Moovn</strong>, you can schedule a free return pickup.
          Moovn will deliver your new Case and pick up your returning items
          in the same trip.
        </p>
      </div>
    </div>
  </accordion-with-header>
</template>

<script>
import AccordionWithHeader from '../../global/sequin/AccordionWithHeader.vue'
import ReturnsMixin from './ReturnsMixin'
import TextLink from '../../global/sequin/TextLink.vue'
import { mapState } from 'vuex'

export default {
  components: {
    AccordionWithHeader,
    TextLink
  },
  mixins: [ReturnsMixin],
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('account', [
      'pickupLocations'
    ]),
    goPickupLocation () {
      const locations = this.pickupLocations.filter(x => x.name === 'Armoire Go')
      this.$logger.info(locations)
      return locations.length > 0 ? locations[0] : null
    }
  }
}
</script>
