<template>
  <div
    class="pt-2 pt-md-3"
    :class="{ 'col-8 offset-3': !isMobile }">
    <div v-if="isMobile">
      <action-header page-header>
        <h5>Returns</h5>
      </action-header>
    </div>
    <div v-else>
      <bread-crumb
        class="mb-3"
        current-location="My Returns"
        :crumbs="[{ name: 'Account', to: { name: 'account' } }]"/>
      <h2>Returns</h2>
    </div>
    <returns-label-packaging
      class="mt-md-4 mt-0"
      :expanded="sectionExpanded === 'labels'"
      :class="{ 'border-top-0': isMobile }"
      @accordion-click="toggleExpanded('labels')"/>
    <returns-free-pickups
      :expanded="sectionExpanded === 'pickups'"
      class="border-top-0"
      @accordion-click="toggleExpanded('pickups')"/>
    <returns-tracking
      :expanded="sectionExpanded === 'tracking'"
      class="border-top-0"
      @accordion-click="toggleExpanded('tracking')"/>
    <returns-support
      :expanded="sectionExpanded === 'support'"
      class="border-top-0"
      @accordion-click="toggleExpanded('support')"/>
  </div>
</template>

<script>
import BreadCrumb from '../../global/sequin/BreadCrumb'
import ActionHeader from '../../global/sequin/ActionHeader'
import ReturnsLabelPackaging from './ReturnsLabelPackaging.vue'
import ReturnsFreePickups from './ReturnsFreePickups.vue'
import ReturnsTracking from './ReturnsTracking.vue'
import ReturnsSupport from './ReturnsSupport.vue'

export default {
  name: 'ReturnsPage',
  components: {
    BreadCrumb,
    ActionHeader,
    ReturnsFreePickups,
    ReturnsLabelPackaging,
    ReturnsTracking,
    ReturnsSupport
  },
  data () {
    return {
      sectionExpanded: this.$route.params.section || ''
    }
  },
  methods: {
    toggleExpanded (section) {
      if (this.sectionExpanded === section) {
        this.sectionExpanded = ''
      } else {
        this.sectionExpanded = section
      }
    }
  }
}
</script>
