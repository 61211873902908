<template>
  <PageHeader
    :class="{'prospect' : (!isAuthenticated && showVariant)}"
    :title="name"
    :description="description"
    :image-url="bannerImageUrl">
    <template #topRight>
      <CollectionOwner
        v-if="showCollectionOwner"
        :owner="collectionOwner"
        :subtitle="subtitle"
        @on-edit-collection="emit('on-edit-collection', $event)"
        @on-delete-collection="emit('on-delete-collection', $event)"
        @on-share-style-color-set-page="emit('on-share-style-color-set-page', $event)"/>
    </template>
    <template #description>
      <div v-if="styleColorSet.source === 'stylist'">
        <p v-if="styleColorSet.stylist">
          Styled by {{ styleColorSet.stylist.firstName }}
        </p>
        <p v-if="styleColorSet.stylingNote">
          {{ styleColorSet.stylingNote }}
        </p>
      </div>
      <p v-if="showCollectionOwner && isTabletOrSmaller">
        {{ collectionOwnerText }}
      </p>
    </template>
  </PageHeader>
</template>

<script setup>
import { computed } from 'vue'
import useClient from '@/composables/client.js'
import { useHomepageRedesignExperiment } from '@shared/composables/experiment.js'
import useScreenSize from '@shared/composables/screenSize.js'
import useStyleColorSet from '@/composables/styleColorSet.js'
import PageHeader from '@shared/components/ADORN/PageHeader.vue'
import CollectionOwner from '@/components/global/CollectionOwner.vue'

const { isTabletOrSmaller } = useScreenSize()

const props = defineProps({
  styleColorSet: {
    type: Object,
    required: true
  },
  showCollectionOwner: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits([
  'on-edit-collection',
  'on-delete-collection',
  'on-share-style-color-set-page'
])
const { isAuthenticated } = useClient()
const { showVariant } = useHomepageRedesignExperiment()
const {
  bannerImageUrl,
  name,
  description,
  collectionOwner,
  isOwnCollection,
  subtitle
} = useStyleColorSet(() => props.styleColorSet)

const collectionOwnerText = computed(() =>
  isOwnCollection.value
    ? subtitle.value
    : `${subtitle.value} ${collectionOwner.value.name}`
)
</script>

<style lang="scss" scoped>
@import './StyleColorSetPage.scss';
</style>
