<template>
  <div class="mt-3">
    <h5 class="mb-0">
      {{ subscribeSourcePrompt }}
    </h5>
    <div
      :class="{'mobile flex-wrap': isMobile, 'rejoin flex-wrap': isRejoin}"
      class="inputs d-flex align-items-center">
      <dropdown-single-select
        :model-value="leadSource"
        class="leadSource w-100"
        :class="{'mobile': isMobile, 'rejoin': isRejoin }"
        small
        :initial-selection-index="selectionIndex"
        :dropdown-options="subscribeSourceOptions"
        @update:modelValue="onLeadSourceUpdate"/>
      <base-input
        v-if="leadSource && subscribeSourceDetailPrompt"
        v-model="leadSourceDetail"
        class="w-100"
        :class="{'mobile': isMobile, 'rejoin': isRejoin}"
        :name="subscribeSourceDetailPrompt"
        :label="`${subscribeSourceDetailPrompt} (Required)`"/>
      <dropdown-single-select
        v-else-if="leadSource && subscribeSourceDetailOptions"
        v-model="leadSourceDetail"
        class="leadSourceDetail dropdown"
        :class="{'mobile': isMobile, 'rejoin': isRejoin}"
        small
        placeholder="Select one (Required)"
        :dropdown-options="subscribeSourceDetailOptions"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DropdownSingleSelect from '../global/sequin/DropdownSingleSelect'
import { vuexAccessors } from '../global/helpers/vuex'
import { leadSourceOptions, rejoinOptions } from '@/store/modules/subscribe'
import BaseInput from '@/components/global/BaseInput.vue'

export default {
  components: {
    DropdownSingleSelect,
    BaseInput
  },
  computed: {
    ...mapState('subscribe', [
      'referralCode'
    ]),
    ...mapState('client', [
      'membershipStatus'
    ]),
    ...vuexAccessors('subscribe', [
      'leadSource',
      'leadSourceDetail',
      'leadSourceDetailRequired'
    ]),
    isRejoin () {
      return this.membershipStatus === 'former_member'
    },
    subscribeSourcePrompt () {
      return this.isRejoin
        ? 'What brought you back to Armoire?'
        : 'How did you hear about Armoire?'
    },
    subscribeSourceOptions () {
      return this.isRejoin
        ? rejoinOptions
        : leadSourceOptions
    },
    selectionIndex () {
      return this.leadSource ? Math.max(this.subscribeSourceOptions.findIndex(subscribeSourceOption => subscribeSourceOption.value === this.leadSource), 0) : 0
    },
    subscribeSourceDetailPrompt () {
      return this.subscribeSourceOptions[this.selectionIndex].detailPrompt()
    },
    subscribeSourceDetailOptions () {
      return this.subscribeSourceOptions[this.selectionIndex].detailOptions
    }
  },
  mounted () {
    if (this.referralCode) {
      this.leadSource = 'referral'
      this.leadSourceDetail = this.referralCode
    }
  },
  methods: {
    onLeadSourceUpdate (newValue) {
      if (this.leadSource !== newValue) this.leadSourceDetail = null
      this.leadSource = newValue
      this.leadSourceDetailRequired = !!this.subscribeSourceDetailPrompt || !!this.subscribeSourceDetailOptions
    }
  }
}
</script>

<style lang="scss" scoped>

.inputs {
  height: 80px;

  &.mobile, &.rejoin {
    height: unset;
  }

  &.rejoin {
    &:not(.mobile) {
      margin-bottom: 12px;
    }
  }
}

.leadSourceDetail {
  width: 100%;
  &.mobile, &.rejoin {
    width: 100%;
    margin-top: 0;

    &.dropdown {
      margin-top: 4px;
    }
  }
}
:deep(.form-group) {
    margin-top: 0 !important;
    .input-group {
      margin-top: 0 !important;
    }
  }
.leadSource {
  margin-right: 16px;

  &.mobile, &.rejoin {
    width: 100%;
    margin-right: 0;
    margin-top: 18px;
  }

}

</style>
