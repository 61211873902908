<template>
  <div
    ref="wrapper"
    class="wrapper">
    <ActionHeader
      v-if="isTabletOrSmaller"
      class="p-3 border-bottom"
      :fallback-route="{ name: 'closet-sections'}">
      {{ mobileHeaderTitle }}
      <template #rightHeaderSlot>
        <CollectionOwner
          v-if="showCollectionOwner"
          :owner="collectionOwner"
          @on-edit-collection="emit('on-edit-collection', $event)"
          @on-delete-collection="emit('on-delete-collection', $event)"
          @on-share-style-color-set-page="emit('on-share-style-color-set-page', $event)"/>
      </template>
    </ActionHeader>
    <div>
      <StyleColorSetPageHeader
        :style-color-set="styleColorSet"
        :show-collection-owner="showCollectionOwner"
        @on-edit-collection="emit('on-edit-collection', $event)"
        @on-delete-collection="emit('on-delete-collection', $event)"
        @on-share-style-color-set-page="emit('on-share-style-color-set-page', $event)"/>
      <div class="container-fluid">
        <slot name="aboveStyles"/>
        <div
          v-if="!hideFilters"
          class="filter-section">
          <div
            v-if="styleColorSet.showAvailabilityToggle"
            class="available-now-toggle">
            <p class="mb-0 me-2 semi-bold small-text">
              Available Now
            </p>
            <SequinToggle
              :state="styleColorSet.available"
              @toggle-click="emit('on-available-now-toggle', $event)"/>
          </div>
          <div
            v-if="selectedFilters.length > 0"
            class="filter-tags-wrapper"
            :class="{'order-last' : isTabletOrSmaller}">
            <div class="filter-tags">
              <SequinTag
                v-for="filter in selectedFilters"
                :key="filter.value"
                class="me-2"
                variant="secondary">
                {{ filter.text }}
                <PlainButton
                  class="p-1"
                  @click="$emit('remove-filter', { filter })">
                  <SvgX
                    color="white"
                    :width="15"
                    :height="15"/>
                </PlainButton>
              </SequinTag>
            </div>
            <div
              class="filter-tags-clear-button"
              :class="{'order-first' : isTabletOrSmaller}">
              <TextLink
                variant="primary-new"
                @click="$emit('clear-all-filters')">
                Clear All
              </TextLink>
            </div>
          </div>
          <div class="filter-button-items-length d-flex justify-content-end align-items-center">
            <PlainButton
              v-if="!isTabletOrSmaller"
              class="filters-button"
              @click="openFilterFlyout()">
              <SvgCircleIcon
                :width="32"
                :height="32"
                :padding="14"
                :stroke="2"
                variant="plum">
                <SvgFilters/>
              </SvgCircleIcon>
              <TextLink
                variant="primary-new"
                @click="openFilterFlyout()">
                Filters:
              </TextLink>
            </PlainButton>
            <p class="whitespace-no-wrap m-0 semi-bold">
              {{ length }} items
            </p>
          </div>
        </div>

        <!-- styles list -->
        <div
          ref="stylesList"
          class="row">
          <span
            v-if="styleColorsLoading"
            class="row">
            <div
              v-for="index in 12"
              :key="index"
              class="col-6 col-md-3 col-lg-2 mb-4">
              <StyleColorPreviewPlaceholder/>
            </div>
          </span>
          <div
            v-if="noStyleColors && !hideFilters && !styleColorsLoading"
            class="col-12 text-center">
            <b>No Results Found</b><br>
            <div v-if="selectedFilters.length > 0">
              <p>Try removing filters to see more options</p><br>
              <TextLink
                class="ps-2"
                @click="$emit('clear-all-filters')">
                Clear All Filters
              </TextLink>
            </div>
          </div>
          <StyleColorPreview
            v-for="(styleColor, index) in styleColors"
            :key="`${styleColorSet.source || styleColorSet.id}-${styleColor.id}`"
            class="col-6 col-md-3 col-lg-2 mb-4"
            :description-props="{
              showStocking: true
            }"
            :style-color-source-index="index"
            :style-color="styleColor"
            :style-color-set="styleColorSet"
            size="auto"/>

          <StyleColorPreviewPlaceholder
            v-for="index in numPlaceholders"
            :key="index"
            class="col-6 col-sm-3 col-md-2 mb-4"/>
        </div>
        <div
          v-if="isTabletOrSmaller"
          :style="filterSortButtonStyle"
          :class="{'pb-md' : getStartedFooter}"
          class="floating-buttons">
          <FilterSortButton
            @click="openFilterFlyout"/>
        </div>
      </div>
      <slot name="belowStyles"/>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useResizeObserver, useScroll } from '@vueuse/core'
import useScreenSize from '@shared/composables/screenSize.js'
import useStyleColorSet from '@/composables/styleColorSet.js'
import StyleColorSetPageHeader from './StyleColorSetPageHeader.vue'
import StyleColorPreview from '@shared/components/ADORN/StyleColorPreview.vue'
import StyleColorPreviewPlaceholder from '@shared/components/ADORN/StyleColorPreviewPlaceholder.vue'
import CollectionOwner from '@/components/global/CollectionOwner.vue'
import SequinTag from '@/components/global/sequin/SequinTag.vue'
import { useOverlaysStore } from '@/stores/overlays'
import PlainButton from '@/components/global/sequin/PlainButton.vue'
import SequinToggle from '@/components/global/sequin/SequinToggle.vue'
import TextLink from '@/components/global/sequin/TextLink.vue'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import ActionHeader from '@/components/global/sequin/ActionHeader.vue'
import FilterSortButton from '@/components/styleColorSet/FilterSortButton.vue'
import SvgX from '@shared/components/icons/SvgX.vue'
import { useElementPresence, useElementPosition } from '@/composables/element.js'
import SvgFilters from '@/components/global/svg/SvgFilters.vue'

const overlaysStore = useOverlaysStore()
const { isTabletOrSmaller } = useScreenSize()

const props = defineProps({
  styleColorSet: {
    type: Object,
    required: true
  },
  styleColorsLoading: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits([
  'on-edit-collection',
  'on-delete-collection',
  'on-share-style-color-set-page',
  'on-available-now-toggle',
  'clear-all-filters',
  'bottom-of-set'
])
function openFilterFlyout () {
  overlaysStore.openFlyout({ name: 'style-color-set-filters', context: { styleColorSet: props.styleColorSet } })
}
const { sourceType, mobileHeaderTitle, styleColors, length, collectionOwner, location } = useStyleColorSet(() => props.styleColorSet)

const hideFilters = computed(() => {
  return styleColors.value?.length === 0 && (sourceType.value === 'search')
})
const showCollectionOwner = computed(() => {
  return ['collection', 'marketing'].includes(sourceType.value) || location.value === 'featured'
})
const noStyleColors = computed(() => styleColors.value?.length === 0)
const selectedFilters = computed(() => {
  return Object.keys(props.styleColorSet.filters ?? {}).flatMap(filterTypeKey => {
    const filtersOfType = props.styleColorSet.filters[filterTypeKey]
    return filtersOfType.map(filterOfType => ({
      ...props.styleColorSet.filterOptions[filterTypeKey]?.find(filterOption => filterOption.value === filterOfType),
      filterType: filterTypeKey
    }))
  })
})
const numPlaceholders = computed(() => {
  if (!props.styleColorSet || !props.styleColorSet.styleColors || !props.styleColorsLoading) return 0
  const rowSize = 6
  const remainder = props.styleColorSet.styleColors.length % rowSize
  return remainder ? (rowSize - remainder) : 0
})
const wrapper = ref(null)
const { arrivedState, measure } = useScroll(wrapper, { offset: { bottom: 200 } })
const canLoadMore = computed(() => styleColors.value?.length < length.value)
watch(arrivedState, ({ bottom }) => {
  if (bottom && !props.styleColorsLoading && canLoadMore.value) {
    emit('bottom-of-set')
  }
})
useResizeObserver(wrapper, measure)
const getStartedFooterPosition = useElementPosition('#get-started-footer')
const getStartedFooter = useElementPresence('#get-started-footer')
const filterSortButtonStyle = computed(() => ({ bottom: `${getStartedFooterPosition.value.height}px` }))

</script>

<style lang="scss" scoped>
@import './StyleColorSetPage.scss';

.wrapper {
  height: 100vh;
  overflow-y: auto;
}
</style>
