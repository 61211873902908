<template>
  <div class="row p-0">
    <div
      class="col promo compact p-0 py-3"
      :class="{'champagne,  text-center': showReferralBanner || showPromoBanner}">
      <div v-if="showReferralBanner">
        <b class="text-uppercase">
          Your {{ referralDiscountText(membershipPlanSelected) }} off savings
        </b>
        <div>
          will be automatically applied at checkout
        </div>
      </div>
      <div v-else>
        <span v-if="showPromoBanner">
          Your first month for just <b>${{ trialPriceFromPlan(membershipPlanSelected) }}</b>
          <span v-if="defaultPromoForSelectedPlan.promoText">
            {{ defaultPromoForSelectedPlan.promoText }}</span>
          <br>
          Use code <b class="text-uppercase">{{ defaultPromoForSelectedPlan.promoCode.toUpperCase() }}</b>
        </span>
        <router-link
          v-else
          :to=" {path: '/home' }"
          class="p-2 me-0 ms-2 ms-md-5">
          <img
            class="m-0"
            :class="!isMobile && 'ms-2'"
            :width="isMobile ? '116' : '140'"
            height="auto"
            :src="logoSource">
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseNavigationMixin from '../global/BaseNavigationMixin'

export default {
  name: 'SignUpPromoBanner',
  mixins: [
    BaseNavigationMixin
  ],
  computed: {
    ...mapGetters('subscribe', [
      'defaultPromoForSelectedPlan',
      'membershipPlanSelected',
      'trialPriceFromPlan',
      'showReferralBanner',
      'showPromoBanner',
      'referralDiscountText'
    ])
  }
}
</script>
<style lang="scss" scoped>
    .promo {
        color: $armor;
        min-height: 72px;
    }

    .champagne {
      background-color: $champagne;
    }

</style>
