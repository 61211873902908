<template>
  <ul
    v-if="validations.$error"
    :style="{ 'opacity': validations.$error ? 1 : 0 }"
    class="error-messages">
    <li v-if="validations.required?.$invalid">
      This field is required.
    </li>
    <li v-if="validations.email?.$invalid">
      Enter a valid email address.
    </li>
    <li v-if="validations.zipCode?.$invalid">
      Enter a 5-digit zip code.
    </li>
    <li v-if="validations.birthYear?.$invalid">
      Enter a valid year.
    </li>
    <li v-if="validations.dDay?.$invalid">
      Please enter a valid due date.
    </li>
    <li v-if="validations.minLength?.$invalid">
      Must be at least {{ validations.minLength.$params.min }} characters.
    </li>
    <li v-if="validations.maxLength?.$invalid">
      Cannot be longer than {{ validations.maxLength.$params.max }} characters.
    </li>
    <li v-if="validations.notNumeric?.$invalid">
      Your password can't be entirely numeric.
    </li>
    <li v-if="validations.sameAsNewPassword?.$invalid">
      Must match previous field.
    </li>
    <li v-if="validations.djangoPasswordValidation?.$invalid">
      This password is too common or too similar to your personal information.
    </li>
    <li v-if="validations.checkboxMinChecked?.$invalid">
      Must select at least {{ validations.checkboxMinChecked.$params.num }} item<span v-if="validations.checkboxMinChecked.$params.num > 1">s</span>.
    </li>
    <li v-if="validations.minSelected?.$invalid">
      Must select at least {{ validations.minSelected.$params.num }}.
    </li>
    <li v-if="validations.reviewCheckbox?.$invalid">
      Must select at least 1 of these options.
    </li>
    <li v-if="validations.stateAbbr?.$invalid">
      Enter a valid state code (e.g. WA)
    </li>
    <li v-if="validations.phone?.$invalid">
      Enter a valid phone number
    </li>
    <li v-if="validations.validReferralCode?.$invalid">
      May only contain letters, numbers, underscores, and dashes
    </li>
    <li v-if="validations.uniqueReferralCode?.$invalid">
      This URL is already taken.
    </li>
    <li v-if="validations.between?.$invalid">
      Enter a value between {{ validations.between.$params.min }} and {{ validations.between.$params.max }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    validations: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .error-messages {
    // Ensures that only one error message
    // displays at a time
    li:not(:first-child) {
      display: none;
    }
    list-style-type: none;
    padding: 0;
    font-size: 0.8rem;
    margin: 0.2rem 0 0;
    line-height: 0.8rem;
    color: red;
    min-height: 0.8rem;
    height: 12px;
  }
</style>
