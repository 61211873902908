<template>
  <div
    v-if="occasionOptions && occasionOptions.length > 0">
    <h6
      class="mb-0">
      Members Wore This To:
    </h6>

    <horizontal-scroll>
      <div
        v-for="option in occasionOptions"
        :key="option.tag.id"
        class="me-2">
        <filter-select-button
          class="text-capitalize mt-3"
          :selected="getFilterSelected(option.tag)"
          @click="onTagClick(option.tag)">
          {{ option.tag.tag }}  ({{ option.count }})
        </filter-select-button>
      </div>
    </horizontal-scroll>
  </div>
</template>

<script>
import FilterSelectButton from '../../styleColorSet/FilterSelectButton'
import HorizontalScroll from '../../global/sequin/HorizontalScroll'

import { mapActions } from 'vuex'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    FilterSelectButton,
    HorizontalScroll
  },
  props: {
    styleColor: {
      type: Object,
      required: true
    },
    modelValue: {
      type: Array,
      default: undefined
    }
  },
  emits: ['update:modelValue', 'reset-sort'],
  computed: {
    filteredReviews () {
      return this.styleColor?.filteredReviews
    },
    occasionOptions () {
      return this.styleColor?.filterOptions.occasionTags
    }
  },
  methods: {
    ...mapActions('closet', [
      'getFilteredReviews'
    ]),
    getFilterSelected (tag) {
      return this.modelValue.includes(tag.id)
    },
    async onTagClick (tag) {
      const index = this.modelValue.indexOf(tag.id)
      let newValue
      if (index > -1) {
        newValue = this.modelValue.filter((_, i) => i !== index)
      } else {
        newValue = [...this.modelValue, tag.id]
      }
      this.$emit('update:modelValue', newValue)
      this.$emit('reset-sort')
      await this.getFilteredReviews({
        styleColorId: this.styleColor.id,
        tagIds: newValue
      })
    }
  }
}

</script>

<style lang="scss" scoped>
  .button-section {
    overflow-y: scroll;
  }
  :deep(.horizontal-scroll) {
    padding-bottom: 5px;
  }
</style>
