import { defineStore } from 'pinia'
import apiGenericContent from '@shared/api/genericContent'
import { ref, computed } from 'vue'

export const useGenericContentStore = defineStore('generic-content-store', () => {
  const sectionsByLocation = ref([])
  const homepageSectionsLoaded = ref(false)
  const initialSectionsLoaded = computed(() => homepageSectionsLoaded.value)

  const fetchSections = async (location) => {
    homepageSectionsLoaded.value = false
    const response = await apiGenericContent.getGenericContentSections(location)
    sectionsByLocation.value = response.data
    homepageSectionsLoaded.value = true
  }
  const loadInitialData = () => {
    return fetchSections('homepage')
  }

  const whatMembersSaySection = computed(() => sectionsByLocation.value.find(section => section.name === 'what_members_say'))
  const heroImageSection = computed(() => sectionsByLocation.value.find(section => section.name === 'hero_image'))

  return {
    sectionsByLocation,
    fetchSections,
    whatMembersSaySection,
    heroImageSection,
    initialSectionsLoaded,
    loadInitialData
  }
})
