<template>
  <MyClosetSectionPreview :section-id="id">
    <template #empty>
      <div class="empty-section text-center p-xl p-sm-xxl mb-md">
        <div class="py-lg py-md-xxl">
          <div class="fw-semibold">
            See something you love?
          </div>
          <div>
            Tap the heart icon to save it to your Favorites
          </div>
        </div>
      </div>
    </template>
  </MyClosetSectionPreview>
</template>

<script setup>
import { useFavoritesSection } from '@/composables/closetSection.js'
import MyClosetSectionPreview from '../closet/MyClosetSectionPreview.vue'

const { id } = useFavoritesSection({ loadOnMount: true })
</script>

<style lang="scss" scoped>
.empty-section {
  background-color: $origami;
  color: $pewter;
}
</style>
