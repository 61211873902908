<script>
import { mapState, mapActions as mapVuexActions } from 'vuex'
import { mapActions } from 'pinia'
import { slugify } from '@/utils/stringParsing.js'
import { useOverlaysStore } from '@/stores/overlays.js'

export default {
  data () {
    return {
      displayBrowseMenu: false,
      availability: true,
      sort: 'recommended'
    }
  },
  computed: {
    ...mapState('closet', [
      'globalFilters',
      'browseFilters',
      'browseFilterOptions',
      'baseFilters',
      'browseSectionDetails',
      'browseStyleColors'
    ]),
    categories () {
      const cats = {
        title: 'Categories',
        name: 'categories',
        list: this.globalFilters?.options?.categories.map(x => {
          return { text: x.text.replace(' and ', ' & '), value: x.value }
        })
      }
      cats.list?.push({ text: 'Maternity', value: 'maternity' }) // Not a real category from global filters, only shows in browse menu
      return cats
    },
    occasions () {
      return { title: 'Occasions', name: 'occasions', list: this.globalFilters?.options?.occasions.map(x => { return { text: x.text, value: x.value.replace('occasion_', '') } }) }
    },
    browseSet () {
      return {
        name: this.browsePageTitle,
        source: 'browse',
        sourceType: 'browse',
        available: this.availability,
        sort: this.sort,
        filterOptions: this.cleanBrowseFilterOptions,
        filters: this.browseFilters
      }
    },
    isNewToArmoireSection () {
      return this.$route.params.section === 'new'
    },
    browseSection () {
      if (this.isNewToArmoireSection) {
        return 'new'
      }
      return [this.categories, this.occasions].find(item => item.name === this.$route.params.section)
    },
    browseSubsection () {
      if (this.isNewToArmoireSection) {
        return 'new'
      }
      return this.browseSection.list?.find(item => item.value === this.$route.params.subsection)
    },
    browseSubsectionParam () {
      if (this.browseSection === 'brands') { return encodeURIComponent(this.$route.params.subsection) } else { return this.$route.params.subsection }
    },
    browsePageTitle () {
      if (this.isNewToArmoireSection) {
        return 'New to Armoire'
      }
      const text = this.browseSection.list?.find(item => item.value === this.$route.params.subsection).text
      return `Browse ${text || ''}`
    },
    cleanBrowseFilterOptions () {
      const filterOptions = { ...this.browseFilterOptions } || { ...this.globalFilters.options }
      // removing redundant filters
      if (this.browseSection?.name === 'categories' && this.browseSubsection !== 'maternity') {
        delete filterOptions.categories
      }
      if (this.browseSection?.name === 'occasions') {
        delete filterOptions.occasions
      }
      return filterOptions
    },
    emptyFilters () {
      return Object.fromEntries(
        Object.entries(this.browseFilters).map(([key]) => [key, []])
      )
    }
  },
  methods: {
    ...mapVuexActions('closet', [
      'getStyleColorsByBrowseSection',
      'getBrowseSectionFilterOptions',
      'getGlobalFilterOptions'
    ]),
    ...mapActions(useOverlaysStore, [
      'closeFlyout'
    ]),
    closeBrowseMenu () {
      this.displayBrowseMenu = false
    },
    applyFilters ({ filters, availability }) {
      const params =
      {
        section: this.$route.params.section,
        subsection: this.browseSubsectionParam,
        available: availability === undefined ? true : availability,
        filters: filters || { ...this.baseFilters },
        sort: this.sort
      }

      // filters are applied on browsePage load, so check loaded details against params to preserve scroll position & prevent duplicate calls
      const details = this.browseSectionDetails
      const paramsMatch =
        (details.section === this.$route.params.section &&
        details.subsection === this.browseSubsectionParam &&
        details.available === availability &&
        JSON.stringify(details.filters) === JSON.stringify(filters) &&
        details.sort === this.sort)

      if (paramsMatch) {
        this.closeFlyout()
        return
      }
      this.getStyleColorsByBrowseSection({ params: params })
      this.closeFlyout()
    },
    clearFilters () {
      this.selectedFilters = { ...this.baseFilters }
      const params =
      {
        section: this.$route.params.section,
        subsection: this.browseSubsectionParam,
        available: this.availability,
        filters: { ...this.baseFilters },
        sort: this.sort
      }

      this.getStyleColorsByBrowseSection({ params: params, filters: this.emptyFilters })
      this.closeFlyout()
    },
    toggleAvailability () {
      this.availability = !this.availability
      this.applyFilters({ filters: this.selectedFilters, availability: this.availability })
    },
    removeAvailability () {
      this.availability = false
      this.applyFilters({ filters: this.selectedFilters, availability: this.availability })
    },
    slugifiedBrand (brand) {
      return slugify(brand)
    }
  }
}
</script>
