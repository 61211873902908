import { useExperimentsStore } from '@shared/stores/experiments.js'
import { useClientStore } from '@shared/stores/client.js'
import { toPascalCase } from '@/utils/stringParsing'
import logger from '@/logger'

export default function useAnalytics () {
  const { getAnalyticsData } = useExperimentsStore()
  const client = useClientStore()

  async function track (eventName, eventProperties = {}) {
    logger.debug('track composable', eventName, eventProperties)
    if (window.analytics) {
      if (client?.email) {
        eventProperties.email = client.email
        logger.debug('track composable added email to properties', eventName, eventProperties)
      }
      window.analytics.track(eventName, eventProperties)
    }
  }

  async function trackPageView (pageName, data = {}) {
    const experimentData = await getAnalyticsData()
    const eventProperties = {
      ...experimentData,
      ...data
    }
    track(`Viewed ${pageName}`, eventProperties)
  }

  async function trackClickEvent (appEntryPoint, label) {
    await track(`Clicked ${toPascalCase(appEntryPoint)} ${label}`)
  }

  return {
    track,
    trackPageView,
    trackClickEvent
  }
}
