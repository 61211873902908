<template>
  <div
    v-if="isMobile"
    class="pt-4 mb-3">
    <action-header page-header>
      <h5>Rewards</h5>
    </action-header>
  </div>
  <div
    v-else
    class="container pt-4 px-0">
    <bread-crumb
      class="mb-3 mx-3"
      current-location="Rewards"
      :crumbs="[{ name: 'Account', to: { name: 'account' } }]"/>
    <h2
      class="pb-3 mx-2 mx-md-3 text-md-start">
      My Rewards
    </h2>
  </div>
  <div class="container">
    <div class="row">
      <div
        class="row bg-white pt-4">
        <div class="col">
          <div class="content-wrapper text-md-start">
            <sequin-pivot
              v-if="itemPromotions"
              class="ms-3"
              :tabs="Object.values(tabs)
                .filter(tab => tab.showWhenEmpty || tab.promotions.length > 0)
                .map(tab => ({ 'id': tab.id, 'name': tab.name }))"
              @select-tab="setTabSelected"/>
          </div>
          <div class="row bg-origami cards py-4">
            <div
              v-if="itemPromotions"
              class="content-wrapper row">
              <div
                v-for="promotion in itemPromotions[tabSelected]"
                :key="promotion.promoCode"
                class="p-0 text-start col-12 px-md-3">
                <sequin-card class="my-2">
                  <sequin-tag
                    variant="success"
                    light>
                    coupon
                  </sequin-tag>
                  <div :class="{ 'd-flex': !isMobile}">
                    <h4 class="mt-3 col-md-5 ps-0 description">
                      {{ promoDescription(promotion) }}
                    </h4>
                    <div class="d-flex flex-row justify-content-between w-100 mt-md-3">
                      <p class="col-md-8 text-md-center ps-0">
                        Code: <b class="text-uppercase">
                          {{ promotion.promoCode }}
                        </b>
                      </p>
                      <text-link
                        v-if="tabSelected === 'available' && codeCopied !== promotion.promoCode"
                        class="text-end px-0 col-4"
                        @click="copyPromoCode(promotion.promoCode)">
                        Copy Code
                      </text-link>
                      <div
                        v-else-if="codeCopied === promotion.promoCode"
                        class="d-flex align-items-center justify-content-end font-weight-bold copied">
                        <svg-circle-icon
                          variant="success"
                          :height="18"
                          :width="18"
                          class="icon-container">
                          <svg-check/>
                        </svg-circle-icon>
                        Copied!
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="tabSelected === 'redeemed'"
                    class="mb-0 d-flex align-items-center expiration">
                    <svg-circle-icon
                      variant="success"
                      :height="18"
                      :width="18"
                      class="icon-container">
                      <svg-check/>
                    </svg-circle-icon>
                    <div>
                      <span class="redeemed">
                        Redeemed</span> on {{ toMonthDayYearString(promotion.redeemed) }} -
                      <em>{{ promotion.itemPurchased }}</em>
                    </div>
                  </div>
                  <div
                    v-else-if="promotion.expiration"
                    class="d-flex mb-0 align-items-center expiration">
                    <svg-circle-icon
                      v-if="tabSelected === 'expired'"
                      variant="danger"
                      :height="18"
                      :width="18"
                      class="icon-container">
                      <svg-x/>
                    </svg-circle-icon>
                    <span
                      v-if="tabSelected === 'expired'"
                      class="me-1 expired">
                      Expired
                    </span>
                    <span
                      v-else
                      class="me-1">
                      Expires
                    </span>
                    on {{ toMonthDayYearString(promotion.expiration) }}
                  </div>
                </sequin-card>
              </div>
              <!-- Empty state -->
              <sequin-card
                v-if="tabs[tabSelected].showWhenEmpty && !itemPromotions || tabs[tabSelected].promotions.length === 0"
                class="col my-2 mx-md-3">
                <div
                  class="w-100 text-start"
                  :class="{ 'd-flex': !isMobile}">
                  <img
                    class="rewards-empty-icon"
                    :src="getImageUrl('rewards-empty.jpg')">
                  <div>
                    <p class="mb-1">
                      <strong>Check back soon!</strong>
                    </p>
                    <p class="mb-1">
                      You don’t have any available rewards, but your next perk is just around the corner!
                    </p>
                  </div>
                </div>
              </sequin-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { formatPrice, toMonthDayYearString } from '@/utils/stringParsing.js'
import BreadCrumb from '../global/sequin/BreadCrumb'
import SequinCard from '../global/sequin/SequinCard'
import SequinPivot from '../global/sequin/SequinPivot'
import SequinTag from '../global/sequin/SequinTag'
import TextLink from '../global/sequin/TextLink'
import ActionHeader from '@/components/global/sequin/ActionHeader.vue'
import SvgCircleIcon from '@/components/global/svg/SvgCircleIcon.vue'
import SvgCheck from '@/components/global/svg/SvgCheck.vue'
import SvgX from '@/components/global/svg/SvgX.vue'

export default {
  name: 'RewardsPage',
  components: {
    ActionHeader,
    BreadCrumb,
    SequinPivot,
    SequinCard,
    SequinTag,
    TextLink,
    SvgCircleIcon,
    SvgCheck,
    SvgX
  },
  data () {
    return {
      tabSelected: 'available',
      codeCopied: null
    }
  },
  computed: {
    ...mapState('account', [
      'itemPromotions'
    ]),
    tabs () {
      return {
        available: {
          id: 'available',
          name: 'Available',
          showWhenEmpty: true,
          promotions: this.itemPromotions ? this.itemPromotions.available : []
        },
        redeemed: {
          id: 'redeemed',
          name: 'Redeemed',
          showWhenEmpty: false,
          promotions: this.itemPromotions ? this.itemPromotions.redeemed : []
        },
        expired: {
          id: 'expired',
          name: 'Expired',
          showWhenEmpty: false,
          promotions: this.itemPromotions ? this.itemPromotions.expired : []
        }
      }
    }
  },
  mounted () {
    this.getItemPromotions()
  },
  methods: {
    ...mapActions('account', [
      'getItemPromotions'
    ]),
    toMonthDayYearString,
    promoDescription (promotion) {
      if (promotion.description) { return promotion.description }
      const off = promotion.amountOff ? `${formatPrice(promotion.amountOff)}` : `${promotion.percentOff}%`
      const item = promotion.forItem ? `item ${promotion.forItem}` : 'an item you love'
      return `Take ${off} off ${item}`
    },
    copyPromoCode (promotionCode) {
      const el = document.createElement('textarea')
      el.value = promotionCode.toUpperCase()
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.codeCopied = promotionCode
    },
    getImageUrl (imageUrl) {
      return `${process.env.VUE_APP_WEBSITE_IMAGES_URL}${imageUrl}`
    },
    setTabSelected ({ id }) {
      this.tabSelected = id
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-container {
  line-height: 20px;
  margin-right: 4px;
}
.rewards-empty-icon {
  height: 36px;
  width: auto;
  margin: 4px 24px 16px 0;
}
.cards {
  min-height: 84vh;
}
.description {
  letter-spacing: 0;
}
.copied, .sequin-text-link {
  line-height: 20px !important;
  height: 20px;
  margin: 0 inherit;
}
.copied {
  color: $success-light !important;
  font-size: 14px !important;
}
.expiration {
  color: $pewter !important;
  font-size: 14px !important;
  line-height: 20px !important;
  min-height: 20px;

  span {
    color: $pewter;
    font-size: 14px !important;
    line-height: 20px !important;
    min-height: 20px;
  }
}
.expired {
  color: $danger-dark !important;
  font-weight: bold !important;
}
.redeemed {
  color: $success-light !important;
  font-weight: bold !important;
}
</style>
