<template>
  <StyleColorSetPage
    :style-color-set="{
      ...browseSet,
      length: browseLength,
      filters: selectedFilters,
      styleColors: browseStyleColorsInfo,
      showAvailabilityToggle: true
    }"
    :style-colors-loading="!browseSectionLoaded || browseSectionLoadingPage"
    @bottom-of-set="debounceNextPage"
    @on-available-now-toggle="toggleAvailability()"
    @clear-all-filters="clearFilters"
    @remove-filter="removeFilter"
    @on-share-style-color-set-page="openShareModal(shareContext)"/>
</template>

<script>
import browseMixin from './browseMixin.vue'
import StyleColorSetPage from '@/components/styleColorSet/StyleColorSetPage.vue'

import { mapActions as mapVuexActions, mapState } from 'vuex'
import { mapActions } from 'pinia'
import { track } from '../../../plugins/analytics'
import { useOverlaysStore } from '@/stores/overlays.js'
import { useModals } from '@/composables/modals.js'

export default {
  name: 'BrowsePage',
  components: {
    StyleColorSetPage
  },
  mixins: [browseMixin],
  setup () {
    const { openShareModal } = useModals()
    return {
      openShareModal
    }
  },
  data () {
    return {
      selectedFilters: {}
    }
  },
  computed: {
    ...mapState('closet', [
      'browseStyleColors',
      'browseSectionLoaded',
      'browseSectionLoadingPage',
      'baseFilters',
      'browseFilters'
    ]),
    browseStyleColorsInfo () {
      return this.browseStyleColors.data?.results
    },
    browseLength () {
      return this.browseStyleColors.data?.count
    },
    queryParamFilters () {
      const filters = { ...this.baseFilters }
      const filterEntries = Object.entries(this.$route.query).filter(([k, _]) => !k.startsWith('utm'))
      for (const [key, val] of filterEntries) {
        if (Array.isArray(val)) {
          filters[key] = val
        } else {
          filters[key] = [val]
        }
      }
      return filters
    },
    shareContext () {
      return { ...this.browseSet, styleColors: this.browseStyleColorsInfo }
    }
  },
  watch: {
    browseFilters (query) {
      if (!this.browseSet) {
        return
      }
      // Stringifying the filters for comparison is good enough for this use case
      const filterChanged = JSON.stringify(this.queryParamFilters) !== JSON.stringify(query)
      if (filterChanged) {
        this.$router.replace({ ...this.$route, query: { ...this.$route.query, ...query } }).catch(() => {
          // Do nothing. Allow the router to intercept as necessary.
        })
      }
    },
    queryParamFilters (query) {
      this.selectedFilters = { ...query }
    }
  },
  created () {
    track('Viewed Browse Page')
    this.selectedFilters = { ...this.queryParamFilters }
    // Global filters may not be loaded in the non-signed in case
    if (!this.globalFilters?.options) {
      this.getGlobalFilterOptions()
    }
  },
  async mounted () {
    const params = {
      section: this.$route.params.section,
      subsection: this.browseSubsectionParam,
      available: this.availability === undefined ? true : this.availability,
      filters: this.selectedFilters,
      sort: this.sort
    }
    if (!this.browseSet?.styleColors) {
      // BaseFlyout data is not very reactive, this is to keep the flyout from being laggy & have loading UI state present
      await this.getBrowseSectionFilterOptions({ ...params, filters: this.emptyFilters })
      this.getStyleColorsByBrowseSection({ params })
    }
  },
  methods: {
    ...mapActions(useOverlaysStore, [
      'openFlyout'
    ]),
    ...mapVuexActions('closet', [
      'getBrowseSectionsNextPage'
    ]),
    updateSelectedFilters ({ type, value }) {
      this.selectedFilters[type] = value
    },
    applySort (sort) {
      this.sort = sort || this.browseSet.sort
      this.applyFilters({ filters: this.selectedFilters, availability: this.availability })
    },
    removeFilter ({ filter }) {
      this.selectedFilters[filter.filterType] = this.selectedFilters[filter.filterType].filter(x => x !== filter.value)
      this.applyFilters({ filters: this.selectedFilters, availability: this.availability })
    },
    debounceNextPage () {
      clearTimeout(this.debounceNextBrowsePageTimeout)
      this.debounceNextBrowsePageTimeout = setTimeout(() => {
        this.getBrowseSectionsNextPage()
      }, 300)
    }
  }
}
</script>
