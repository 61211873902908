<template>
  <form @submit.prevent="onSubmit">
    <base-form-errors :errors="formErrors"/>
    <div
      class="row">
      <div class="col pe-1 my-sm">
        <base-input
          v-model="shippingFirstName"
          :validations="v$.shippingFirstName"
          autocomplete="given-name"
          input-id="first-name"
          label="First name"/>
      </div>
      <div class="col ps-1 my-sm">
        <base-input
          v-model="shippingLastName"
          :validations="v$.shippingLastName"
          autocomplete="family-name"
          input-id="last-name"
          label="Last name"/>
      </div>
    </div>
    <div class="my-sm">
      <base-input
        v-model="addressLine1"
        :validations="v$.addressLine1"
        autocomplete="shipping address-line1"
        label="Address"
        name="addressLine1"/>
    </div>
    <div class="my-sm">
      <base-input
        v-model="addressLine2"
        autocomplete="shipping address-line2"
        helper-text="Apartment, Suite, etc. (optional)"/>
    </div>
    <div class="my-sm">
      <base-input
        v-model="city"
        :validations="v$.city"
        autocomplete="shipping locality"
        label="City"/>
    </div>
    <div class="row">
      <div class="col my-sm">
        <base-input
          v-model="state"
          :validations="v$.state"
          autocomplete="shipping region"
          label="State"/>
      </div>
      <div class="col my-sm">
        <base-input
          v-model="zipcode"
          type="number"
          :validations="v$.zipcode"
          :cleave-options="{
            numericOnly: true,
            blocks: [5]}"
          autocomplete="shipping postal-code"
          label="ZIP code"/>
      </div>
    </div>
    <div
      v-if="showPhoneNumberInput"
      class="row my-sm">
      <div class="col">
        <base-input
          v-model="phone"
          type="tel"
          prepend="+1-"
          :validations="v$.phone"
          :cleave-options="{
            delimiter: '-',
            numericOnly: true,
            blocks: [3, 3, 4]}"
          autocomplete="tel tel-national"
          label="Phone"/>
        <base-checkbox
          v-model="consentToSMSMarketing"
          text="I agree to be contacted by SMS for marketing messages."
          class="mt-xs"
          :input-value="1"/>
      </div>
    </div>
    <base-checkbox
      v-if="showSetSelected"
      v-model="setAsSelectedShipping"
      text="Set as my selected shipping address"
      class="mt-2"
      :input-value="1"/>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, requiredUnless } from '@vuelidate/validators'
import { phone, stateAbbr, zipCode } from '../global/vuelidate/customValidators'
import BaseFormErrors from '@/components/global/BaseFormErrors.vue'
import BaseInput from '@/components/global/BaseInput.vue'
import BaseCheckbox from '@/components/global/BaseCheckbox.vue'

export default {
  components: {
    BaseFormErrors,
    BaseInput,
    BaseCheckbox
  },
  props: {
    // If an address is passed in, this will update the address
    // instead of creating a new address.
    address: {
      type: Object,
      default: null
    },
    showSetSelected: {
      type: Boolean,
      default: false
    },
    setSelectedInitialValue: {
      type: Boolean,
      default: true
    },
    showPhoneNumberInput: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      shippingFirstName: '',
      shippingLastName: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zipcode: '',
      phone: '',
      formErrors: [],
      setAsSelectedShipping: false,
      consentToSMSMarketing: true
    }
  },
  computed: {
    ...mapState('client', [
      'firstName',
      'lastName',
      'shippingAddress',
      'mainPhone'
    ])
  },
  mounted: function () {
    this.setAsSelectedShipping = this.setSelectedInitialValue
    this.shippingFirstName = this.firstName
    this.shippingLastName = this.lastName
    if (this.mainPhone.indexOf('+1-') === 0) {
      this.phone = this.mainPhone.replace('+1-', '')
    } else if (this.mainPhone.indexOf('+1') === 0) {
      this.phone = this.mainPhone.replace('+1', '')
    } else {
      this.phone = this.mainPhone
    }
    if (this.address) {
      this.addressLine1 = this.address.addressLine1
      this.addressLine2 = this.address.addressLine2
      this.city = this.address.city
      this.state = this.address.state
      this.zipcode = this.address.zipcode
    }
  },
  methods: {
    ...mapActions('account', [
      'addAddress',
      'updateAddress',
      'updateClient',
      'selectShippingAddress'
    ]),
    async submit () {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }
      let addedAddress = null

      const clientData = {
        firstName: this.shippingFirstName,
        lastName: this.shippingLastName
      }
      if (this.showPhoneNumberInput) {
        clientData.main_phone = this.phone
        clientData.consent_to_sms_marketing = this.consentToSMSMarketing
      }

      try {
        await this.updateClient(clientData)

        const data = {
          addressLine1: this.addressLine1,
          addressLine2: this.addressLine2,
          city: this.city,
          state: this.state.trim().toUpperCase(),
          zipcode: this.zipcode
        }

        if (this.address !== null && this.address.id) {
          data.id = this.address.id
          await this.updateAddress(data)
        } else {
          addedAddress = await this.addAddress(data)
        }
        if (this.setAsSelectedShipping) {
          await this.selectShippingAddress(addedAddress || data)
        }
        this.$emit('address-added-updated')
        this.formErrors = []
      } catch (error) {
        if (error.correctable) {
          this.$emit('correctable-address-error', { error, setAsSelectedShipping: this.setAsSelectedShipping })
        } else {
          this.formErrors = [
            'Sorry, there appears to be an error in your address. ' +
            'Please make sure you entered it correctly.']
          if (error && error.constructor === Array) {
            this.formErrors = this.formErrors.concat(error)
          } else if (error.errors) {
            this.formErrors = this.formErrors.concat(error.errors)
          }
        }
      }
    }
  },
  validations: {
    shippingFirstName: {
      required
    },
    shippingLastName: {
      required
    },
    addressLine1: {
      required
    },
    city: {
      required
    },
    state: {
      required,
      stateAbbr
    },
    zipcode: {
      required,
      zipCode
    },
    phone: {
      required: requiredUnless(vm => {
        return vm.showPhoneNumberInput
      }),
      phone
    }
  }
}
</script>
