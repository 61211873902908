import { computed } from 'vue'
import { useStore } from 'vuex'

// Composable to simplify access to client data from the Composition API.
// When we convert the client store to Pinia, most if not all of this won't be necessary.
export default function useClient () {
  const store = useStore()

  const isLoaded = computed(() => store.state.client.loaded === true)
  const isAuthenticated = computed(() => store.state.client?.isAuthenticated === true)
  const isImpersonated = computed(() => store.state.client.isImpersonated)

  const clientId = computed(() => store.state.client.id)
  const firstName = computed(() => store.state.client.firstName)
  const lastName = computed(() => store.state.client.lastName)
  const name = computed(() => `${firstName.value} ${lastName.value}`)

  const profileImageUrl = computed(() => store.state.client.profilePic)
  const hasProfileImage = computed(() => profileImageUrl.value?.length > 0)
  const hasCustomProfileImage = computed(() => hasProfileImage.value && !profileImageUrl.value.includes('dresses'))

  const firstCaseDueDate = computed(() => {
    const dueDate = store.state.client.firstCaseDueDate
    return dueDate ? new Date(dueDate) : null
  })
  const paymentIsExpired = computed(() => store.getters['client/paymentIsExpired'])
  const showFirstCaseCountdown = computed(() => store.getters['client/showFirstCaseCountdown'])

  return {
    isLoaded,
    isAuthenticated,
    isImpersonated,
    clientId,
    firstName,
    lastName,
    name,
    profileImageUrl,
    hasProfileImage,
    hasCustomProfileImage,
    firstCaseDueDate,
    paymentIsExpired,
    showFirstCaseCountdown
  }
}
