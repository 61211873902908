<template>
  <div class="d-flex flex-column row-gap-lg">
    <review-single-select
      v-model="fitRating"
      label="Did it fit?"
      :options="likeDislikeOptions"
      :validations="validations.fitRating"/>
    <div v-if="review.fitRating === 'neutral'">
      <h5>
        Tell us how you made it work<span class="required-asterisk">*</span>
      </h5>
      <textarea
        v-model="fitRatingMadeItWork"
        class="fit-rating-text-area form-control"
        placeholder="We love hearing how you styled clothing that wasn't quite the right fit"/>
    </div>
    <div
      v-if="['dislike', 'neutral'].includes(review.fitRating)"
      class="d-flex flex-column row-gap-lg">
      <div>
        <h5>
          Select all that apply<span class="required-asterisk">*</span>
        </h5>
        <ReviewItemCheckboxOptions
          v-model="size"
          :options="sizeOptions"/>
      </div>
      <div v-if="review.size.includes('too big')">
        <h5>Too big in the...</h5>
        <ReviewItemCheckboxOptions
          v-model="tooBig"
          :options="fitOptions"/>
      </div>
      <div v-if="review.size.includes('too small')">
        <h5>Too small in the...</h5>
        <ReviewItemCheckboxOptions
          v-model="tooSmall"
          :options="fitOptions"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ReviewItemCheckboxOptions from './ReviewItemCheckboxOptions.vue'
import ReviewSingleSelect from './ReviewSingleSelect.vue'

export default {
  components: {
    ReviewItemCheckboxOptions,
    ReviewSingleSelect
  },
  model: {
    prop: 'review',
    event: 'change'
  },
  props: {
    review: {
      type: Object,
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    validations: {
      type: Object,
      default: () => {
        return { $touch () {} }
      }
    }
  },
  data () {
    return {
      fullFitOptions: [
        { text: 'Shoulders', value: 'shoulders' },
        { text: 'Bust', value: 'bust' },
        { text: 'Waist', value: 'waist' },
        { text: 'Hips', value: 'hips' },
        { text: 'Rear', value: 'rear' },
        { text: 'Length', value: 'length' }
      ],
      sizeOptions: [
        {
          text: 'Too big',
          value: 'too big'
        },
        {
          text: 'Too small',
          value: 'too small'
        }
      ],
      fitOptionsMap: {
        tops: [
          { text: 'Shoulders', value: 'shoulders' },
          { text: 'Sleeves', value: 'sleeves' },
          { text: 'Bust', value: 'bust' },
          { text: 'Length', value: 'length' }
        ],
        outer: [
          { text: 'Shoulders', value: 'shoulders' },
          { text: 'Sleeves', value: 'sleeves' },
          { text: 'Bust', value: 'bust' },
          { text: 'Length', value: 'length' }
        ],
        dresses: [
          { text: 'Shoulders', value: 'shoulders' },
          { text: 'Bust', value: 'bust' },
          { text: 'Sleeves', value: 'sleeves' },
          { text: 'Waist', value: 'waist' },
          { text: 'Hips', value: 'hips' },
          { text: 'Rear', value: 'rear' },
          { text: 'Length', value: 'length' }
        ],
        jumpsuits: [
          { text: 'Shoulders', value: 'shoulders' },
          { text: 'Bust', value: 'bust' },
          { text: 'Sleeves', value: 'sleeves' },
          { text: 'Waist', value: 'waist' },
          { text: 'Hips', value: 'hips' },
          { text: 'Rear', value: 'rear' },
          { text: 'Length', value: 'length' }
        ],
        bottoms: [
          { text: 'Waist', value: 'waist' },
          { text: 'Hips', value: 'hips' },
          { text: 'Rear', value: 'rear' },
          { text: 'Length', value: 'length' }
        ]
      },
      likeDislikeOptions: [
        {
          text: 'Yes',
          value: 'like'
        },
        {
          text: 'Made it Work',
          value: 'neutral'
        },
        {
          text: 'No',
          value: 'dislike'
        }
      ]
    }
  },
  computed: {
    ...mapState('closet', [
      'styleColorsMap'
    ]),
    department () {
      let department = this.styleColorsMap[this.item.styleColor].style.department
      // Is this necessary?
      if (department === '') department = 'dresses'

      return department
    },
    fitOptions () {
      return this.fitOptionsMap[this.department]
        ? this.fitOptionsMap[this.department]
        : this.fullFitOptions
    },
    fitRating: {
      get () {
        return this.review.fitRating
      },
      set (fitRating) {
        const review = { ...this.review, fitRating }
        if (fitRating === 'like' || fitRating === 'neutral') {
          this.clearDislikes(review)
        }
        this.$emit('change', review)
      }
    },
    fitRatingMadeItWork: {
      get () {
        return this.review.fitRatingMadeItWork
      },
      set (fitRatingMadeItWork) {
        this.$emit('change', { ...this.review, fitRatingMadeItWork })
      }
    },
    size: {
      get () {
        return this.review.size
      },
      set (size) {
        const review = { ...this.review, size }
        if (!size.includes('too big')) {
          review.tooBig = []
        }
        if (!size.includes('too small')) {
          review.tooSmall = []
        }
        this.$emit('change', review)
      }
    },
    tooBig: {
      get () {
        return this.review.tooBig
      },
      set (tooBig) {
        const review = { ...this.review, tooBig }
        this.removeDuplicates(review, 'tooBig', 'tooSmall')
        this.updateFitRatings(review)
        this.$emit('change', review)
      }
    },
    tooSmall: {
      get () {
        return this.review.tooSmall
      },
      set (tooSmall) {
        const review = { ...this.review, tooSmall }
        this.removeDuplicates(review, 'tooSmall', 'tooBig')
        this.updateFitRatings(review)
        this.$emit('change', review)
      }
    }
  },
  methods: {
    removeDuplicates (review, source, target) {
      review[target] = review[target].filter(option => !review[source].includes(option))
    },
    clearDislikes (review) {
      review.tooSmall = []
      review.tooBig = []
      review.size = []
    },
    getRating (review, val) {
      if (review.tooSmall.includes(val) || review.tooBig.includes(val)) {
        return 'dislike'
      }
      if (this.fitOptions.includes(val)) {
        return review.fitRating === 'like' ? 'like' : 'neutral'
      }
      return ''
    },
    getReason (review, val) {
      if (review.tooSmall.includes(val)) return 'too_small'
      if (review.tooBig.includes(val)) return 'too_big'
      return ''
    },
    updateFitRatings (review) {
      review.fitRatingBust = this.getRating(review, 'bust')
      review.fitRatingBustReason = this.getReason(review, 'bust')
      review.fitRatingHips = this.getRating(review, 'hips')
      review.fitRatingHipsReason = this.getReason(review, 'hips')
      review.fitRatingLength = this.getRating(review, 'length')
      review.fitRatingLengthReason = this.getReason(review, 'length')
      review.fitRatingRear = this.getRating(review, 'rear')
      review.fitRatingRearReason = this.getReason(review, 'rear')
      review.fitRatingShoulders = this.getRating(review, 'shoulders')
      review.fitRatingShouldersReason = this.getReason(review, 'shoulders')
      review.fitRatingWaist = this.getRating(review, 'waist')
      review.fitRatingWaistReason = this.getReason(review, 'waist')
    }
  }
}
</script>
<style lang="scss" scoped>
@import './ReviewItem.scss';
</style>
