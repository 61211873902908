<template>
  <BaseAccordion
    :title="title"
    :start-expanded="true"
    :fixed="!isMobile"
    class="review-item-section py-lg">
    <template
      v-if="required"
      #decorator>
      <h6 class="m-0 text-orchid fw-normal">
        *required
      </h6>
    </template>
    <div :class="slotClasses">
      <slot/>
    </div>
  </BaseAccordion>
</template>

<script setup>
import useScreenSize from '@shared/composables/screenSize.js'
import BaseAccordion from '@/components/global/BaseAccordion.vue'

defineProps({
  title: {
    type: String,
    default: null
  },
  required: {
    type: Boolean,
    default: false
  }
})

const { isMobile } = useScreenSize()
const slotClasses = ['d-flex', 'flex-column', 'row-gap-lg']
</script>

<style lang="scss" scoped>
.review-item-section:not(:first-child) {
  border-top: $dark-border;
}
</style>
