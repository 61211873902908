<template>
  <div>
    <div
      class="row">
      <h2 class="col col-12 text-center text-md-start mb-2 ps-md-0">
        {{ header }}
      </h2>
      <app-message
        v-if="!eligibleForChange && ineligibilityReason !== 'RECENT_CHANGE'"
        variant="danger"
        class="col col-12 mb-md-3">
        <div class="text-center text-md-start">
          <h5>Oops!</h5>
          {{ ineligibilityReasonText }}
          <span
            v-if="ineligibilityReason === 'NEW_SUBSCRIPTION'"
            style="cursor: pointer">
            Please reach out to <u @click="openIntercom">Customer Service for assistance</u>.
          </span>
        </div>
      </app-message>
      <div
        v-else
        class="col col-12 text-center text-md-start ps-md-0 mb-2">
        <div v-if="ineligibilityReason === 'RECENT_CHANGE'">
          <p class="semi-bold">
            You’ve already switched plans this {{ duration }}.
          </p>
          <p>
            Need to make another change? Drop us a quick note &mdash; our Member Experience Team will be happy to assist you!
          </p>
          <sequin-button
            mobile-block
            type="a"
            target="_blank"
            href="mailto:hi@armoire.style">
            Request plan change
          </sequin-button>
        </div>
        <span v-else>
          {{ subheader }}
        </span>
      </div>
    </div>
    <div v-if="ineligibilityReason !== 'RECENT_CHANGE'">
      <div class="row">
        <plan-selector
          class="plan-selector"
          :disabled="!eligibleForChange"
          :plans="selectablePlans"
          :selected-plan-id="membershipPlanSelectedId"
          :current-plan-id="currentPlanId"
          @plan-selected="onPlanSelected"/>
      </div>
      <action-footer v-if="eligibleForChange">
        <template
          v-if="needToReturn > 0"
          #message>
          <div
            class="my-3 px-3 text-center text-danger small-text">
            <div>
              <strong>You have {{ outstandingItems }} outstanding items</strong>
            </div>
            <div>
              You can still change to this plan, but you will not be eligible for a new case until we receive
              at least {{ needToReturn }} of these items at our warehouse.
            </div>
          </div>
        </template>
        <template #button>
          <sequin-button
            class="w-330"
            mobile-block
            :disabled="!membershipPlanSelectedId"
            @click="handleClick">
            Continue
          </sequin-button>
        </template>
      </action-footer>
    </div>
  </div>
</template>

<script>
import MembershipMixin from './MembershipMixin'
import ActionFooter from '../ActionFooter'
import AppMessage from '../../global/sequin/AppMessage'
import PlanSelector from '@/components/account/PlanSelector.vue'
import SequinButton from '@/components/global/sequin/SequinButton.vue'
import { track } from '../../../plugins/analytics'
import { mapState } from 'vuex'

export default {
  name: 'PlanSelectPage',
  components: {
    ActionFooter,
    AppMessage,
    PlanSelector,
    SequinButton
  },
  mixins: [MembershipMixin],
  props: {
    header: {
      type: String,
      required: true
    },
    subheader: {
      type: String,
      default: ''
    },
    selectablePlans: {
      type: Array,
      required: true
    },
    confirmationTitle: {
      type: String,
      default: 'Confirm Plan Change'
    },
    breadcrumb: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('client', [
      'plans'
    ]),
    duration () {
      if (this.plans.currentBasePlan.shipmentsPerInterval === 0) {
        return 'week'
      }
      return 'month'
    },
    outstandingItems () {
      return (this.toReturnItems.length + this.deliveredItems.length) || 0
    },
    needToReturn () {
      if (this.membershipPlanSelected) {
        const selectedPlanItems = this.membershipPlanSelected.nItems
        if (selectedPlanItems <= this.outstandingItems) {
          return this.outstandingItems - selectedPlanItems
        }
      }
      return 0
    },
    currentPlanId () {
      if (this.plans.currentBasePlan) {
        return this.plans.currentBasePlan.id
      }
      return ''
    }
  },
  async mounted () {
    if (!this.membershipPlans) {
      await this.fetchPlans()
    }
    this.selectablePlans.forEach(plan => {
      this.getQuote(plan.id)
    })
    this.breadcrumbs = [
      { name: 'Membership', to: { name: 'membership-overview' } },
      { name: this.breadcrumb.name }
    ]
    this.confirmChangeTitle = this.confirmationTitle
    track('Viewed Plan Select Page', {
      source: this.header
    })
  },
  methods: {
    handleClick () {
      this.breadcrumbs = [
        { name: 'Membership', to: { name: 'membership-overview' } },
        this.breadcrumb,
        { name: 'Confirm Plan Change' }
      ]
      this.$router.push({ name: 'confirm-plan-change', query: { planId: this.membershipPlanSelectedId } })
    },
    openIntercom () {
      // eslint-disable-next-line
      Intercom('show')
    }
  }
}
</script>

<style lang="scss" scoped>
  .plan-selector {
    padding-bottom: 128px;
  }
</style>
