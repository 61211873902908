<template>
  <div class="wrapper">
    <div class="container">
      <div class="row">
        <div class="col d-flex align-items-center justify-content-center">
          <div>
            <img
              class="friends-hero"
              alt=""
              src="https://d2ezpplblfgpjx.cloudfront.net/refer-friends-desktop.png">
          </div>
          <div class="content-wrapper">
            <h5 class="pb-2 text-uppercase">
              Refer a Friend
            </h5>
            <h4 class="pb-2">
              {{ header }}
            </h4>
            <p class="hero-text">
              Share Armoire with your friends and network! As a thank you, you'll
              get ${{ referrerCredit }} subscription credit for every friend who signs
              up using your link or code. They'll get <b>our best deal on their first
                month of Armoire</b><span v-if="refereeBonusItems > 0">, plus
                {{ refereeBonusItems }} free bonus items in their first case</span>!
            </p>
            <p class="fine-print hero-text">
              * Subscription credit and initial discount valid for monthly
              subscriptions, including our Unlimited, 7-Item Capsule and
              4-Item Capsule Plans.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReferralBanner',
  props: {
    header: {
      type: String,
      required: true
    },
    referrerCredit: {
      type: Number,
      required: true
    },
    refereeBonusItems: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .wrapper {
    background-color: $origami;
    margin: 0 -15px;
  }

  .content-wrapper {
    padding: 24px 0;
  }

  .friends-hero {
    height: 100%;
    width: auto;
    max-height: 300px;
    margin-right: 2em;
  }
  .hero-text {
    max-width: 700px;
  }
</style>
