<template>
  <div>
    <div class="row">
      <h2
        class="col col-12 text-center text-md-start ps-md-0 mb-3 mb-md-2">
        {{ showConfirmation ? `We'll miss you, ${firstName}!` : 'Cancel Membership' }}
      </h2>
    </div>
    <div>
      <transition-group
        name="fade"
        mode="out-in">
        <div
          v-if="showPlanSelect"
          key="plan-select"
          class="row">
          <h4 class="col col-12 text-center text-md-start ps-md-0 mb-3 mb-md-2">
            {{ deterrentMessage.header }}
          </h4>
          <p
            v-if="deterrentMessage.body"
            class="col col-12 text-center text-md-start ps-md-0">
            {{ deterrentMessage.body }}
          </p>
          <div
            v-if="availableSwaps || bonusItems || creditAvailable || itemPromosAvailable"
            class="deterrent user-benefits">
            <h5 class="col col-12 text-center text-md-start ps-md-0 mb-3 mb-md-2">
              Do you want to use your benefits?
            </h5>
            <ul>
              <li v-if="availableSwaps">
                {{ availableSwaps }} available case swap{{ availableSwaps > 1 ? 's' : '' }}
              </li>
              <li v-if="bonusItems">
                {{ bonusItems }} bonus item{{ bonusItems > 1 ? 's' : '' }}
              </li>
              <li v-if="creditAvailable">
                {{ formatPrice(creditAvailable) }} in unused credit
              </li>
              <li
                v-if="itemPromosAvailable">
                <text-link
                  size="paragraph"
                  variant="primary"
                  type="router-link"
                  name="Rewards"
                  :to="{ name: 'rewards' }">
                  {{ itemPromosAvailable }} coupon{{ itemPromosAvailable > 1 ? 's' : '' }}
                </text-link> to purchase items you love at a discount.
              </li>
            </ul>
            <h5
              v-if="totalValueOfBenefits > 0"
              class="col col-12 text-center text-md-start ps-md-0 mb-3 mb-md-2 semi-bold">
              That's over {{ formatPrice(totalValueOfBenefits) }} in value!
            </h5>
            <p class="col col-12 text-center text-md-start ps-md-0 mb-0 mb-md-0">
              If you cancel, you will lose access to these benefits.
            </p>
          </div>
          <div
            v-if="membershipStatus === 'paused'"
            class="deterrent skip-month">
            <h5 class="col col-12 text-center text-md-start ps-md-0 mb-1 mb-md-2 mt-md-3">
              Have you considered extending your pause?
            </h5>
            <p class="col col-12 text-center text-md-start ps-md-0 mb-0 mb-md-0">
              Extend your pause and retain all your benefits by following the steps on this
              <text-link
                size="paragraph"
                variant="primary"
                type="router-link"
                name="Pause Membership"
                :to="{ name: 'pause' }">
                page
              </text-link>.
            </p>
          </div>
          <div
            v-else
            class="deterrent skip-month">
            <h5 class="col col-12 text-center text-md-start ps-md-0 mb-1 mb-md-2 mt-md-3">
              Have you considered skipping a month?
            </h5>
            <p class="col col-12 text-center text-md-start ps-md-0 mb-0 mb-md-0">
              You can pause your rental membership and retain all your benefits by following the steps on this
              <text-link
                size="paragraph"
                variant="primary"
                type="router-link"
                name="Pause Membership"
                :to="{ name: 'pause' }">
                page
              </text-link>.
            </p>
          </div>
          <div class="deterrent alt-plan">
            <h5 class="col col-12 text-center text-md-start ps-md-0 mb-1 mb-md-2 mt-md-3">
              Maybe another plan is the right fit?
            </h5>
            <p class="col col-12 text-center text-md-start ps-md-0 mb-3 mb-md-4">
              We have a great assortment of plans to help you look great, whether that’s with a monthly case swap or unlimited rental.
            </p>
          </div>
          <div class="col col-12">
            <plan-selector
              :plans="availableMonthlyPlans"
              :selected-plan-id="membershipPlanSelectedId"
              :current-plan-id="currentPlanId"
              @plan-selected="onPlanSelected"/>
          </div>
          <div
            class="deterrent chat-help">
            <h5 class="col col-12 text-center text-md-start ps-md-0 mb-1 mb-md-2">
              Other questions?
            </h5>
            <p class="col col-12 text-center text-md-start ps-md-0 mb-0 mb-md-0">
              <text-link
                size="paragraph"
                variant="primary"
                type="a"
                name="Chat with customer support"
                @click="initiateChatClick">
                Chat
              </text-link> with our customer support team to get answers.
            </p>
            <p class="col col-12 text-center text-md-start ps-md-0 mb-0 mb-md-0">
              See
              <text-link
                size="paragraph"
                variant="primary"
                type="a"
                href="https://support.armoire.style/faq">
                here
              </text-link> for more details on our cancellation policy.
            </p>
          </div>
        </div>
        <div
          v-if="showCancelReasonForm"
          key="cancel-reason">
          <div class="row">
            <cancel-deterrent
              class="col col-12"/>
            <cancel-reason
              :cancel-reason="cancelReason"
              :validations="v$.cancelReason"
              class="col col-12"
              @input="updateCancelReason"/>
          </div>
        </div>
        <div
          v-if="showConfirmation"
          key="confirmation"
          class="row">
          <cancel-confirmation/>
        </div>
      </transition-group>
    </div>
    <div
      v-if="!showConfirmation"
      class="my-4">
      <div
        v-for="error in errors"
        :key="error"
        class="my-2 text-center text-danger"
        v-html="error"/>
      <sequin-button
        class="w-330"
        mobile-block
        :disabled="(showCancelReasonForm && v$.cancelReason.$invalid) || submitting"
        @click="handleClick">
        {{ buttonText }}
      </sequin-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MembershipMixin from './MembershipMixin'
import { toMonthDayYearString, formatPrice } from '@/utils/stringParsing.js'
import CancelConfirmation from './CancelConfirmation'
import { track } from '../../../plugins/analytics'
import CancelDeterrent from './CancelDeterrent'
import CancelReason from './CancelReason'
import { useVuelidate } from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import TextLink from '../../global/sequin/TextLink'
import PlanSelector from '@/components/account/PlanSelector.vue'
import SequinButton from '@/components/global/sequin/SequinButton.vue'

export default {
  name: 'CancelPage',
  components: {
    CancelConfirmation,
    CancelDeterrent,
    CancelReason,
    TextLink,
    PlanSelector,
    SequinButton
  },
  mixins: [MembershipMixin],
  setup () {
    return { v$: useVuelidate() }
  },
  data () {
    return {
      showPlanSelect: true,
      showCancelReasonForm: false,
      showConfirmation: false,
      submitting: false,
      cancelReason: {
        reason_choice_primary: null,
        reason_choice_secondary: {},
        feedback: ''
      },
      errors: null
    }
  },
  computed: {
    ...mapState('account', [
      'itemPromotions'
    ]),
    ...mapState('client', [
      'firstName',
      'paidThrough',
      'membershipStatus',
      'membershipDetails',
      'balance',
      'benefitsValue'
    ]),
    ...mapState('subscribe', [
      'referralCampaign'
    ]),
    buttonText () {
      if (this.showPlanSelect) {
        return this.membershipPlanSelectedId
          ? 'Continue'
          : "No thanks, I'd like to cancel"
      }
      return 'Cancel My Membership'
    },
    deterrentMessage () {
      if (this.plans && this.currentBasePlan && this.currentBasePlan.prepayment) {
        return {
          header: 'Would a monthly plan be a better fit?',
          body: "If you switch to monthly plan, we'll apply any unused prepay credit to it."
        }
      }
      return {
        header: 'Before you go:'
      }
    },
    availableSwaps () {
      return this.membershipDetails.allowedShipments > 0 ? this.membershipDetails.allowedShipments : null
    },
    bonusItems () {
      return this.membershipDetails.bonusItems.length > 0 ? this.membershipDetails.bonusItems.length : null
    },
    creditAvailable () {
      return this.balance < 0 ? -this.balance : null
    },
    itemPromosAvailable () {
      return this.itemPromotions?.available ? this.itemPromotions.available.length : null
    },
    totalValueOfBenefits () {
      return this.benefitsValue > 0 ? this.benefitsValue : null
    },
    currentPlanId () {
      if (this.plans.currentBasePlan) {
        return this.plans.currentBasePlan.id
      }
      return ''
    }
  },
  created () {
    if (this.cancellationScheduled) {
      this.showPlanSelect = false
      this.showConfirmation = true
    } else {
      this.showPlanSelect = this.eligibleForChange
      if (!this.showPlanSelect) {
        this.showCancelReasonForm = true
      }
    }
  },
  async mounted () {
    if (!this.membershipPlans) {
      await this.fetchPlans()
    }
    this.availableMonthlyPlans.forEach(plan => this.getQuote(plan.id))
    this.breadcrumbs = [
      { name: 'Membership', to: { name: 'membership-overview' } },
      { name: 'Cancel Membership' }
    ]
    this.getBilling()
    this.getItemPromotions()
    track('Viewed Cancel Membership', { currentPlan: this.plans.currentBasePlan.displayName })
  },
  methods: {
    ...mapActions('client', [
      'getClient',
      'getBilling'
    ]),
    ...mapActions('account', [
      'cancelSubscription',
      'getItemPromotions'
    ]),
    toMonthDayYearString,
    formatPrice,
    handleClick () {
      if (this.showPlanSelect) {
        this.planSelectHandleClick()
      } else if (this.showCancelReasonForm) {
        this.cancelReasonHandleClick()
      }
    },
    planSelectHandleClick () {
      if (this.membershipPlanSelectedId) {
        this.breadcrumbs = [
          { name: 'Membership', to: { name: 'membership-overview' } },
          { name: 'Cancel Membership', to: 'cancel' },
          { name: 'Confirm Plan Change' }
        ]
        this.confirmChangeTitle = 'Confirm Plan Change'
        this.$router.push({ name: 'confirm-plan-change' })
      } else {
        this.showPlanSelect = false
        this.showCancelReasonForm = true
      }
    },
    initiateChatClick () {
      window.Intercom('showNewMessage')
    },
    async cancelReasonHandleClick () {
      this.submitting = true
      this.errors = null
      try {
        await this.cancelSubscription(this.cancelReason)
        await this.getClient()
        this.showCancelReasonForm = false
        this.showConfirmation = true
        this.submitting = false
      } catch (err) {
        this.$logger.error(err)
        this.errors = err
        this.submitting = false
      }
    },
    updateCancelReason (data) {
      this.$logger.debug('cancel reason', data)
      this.cancelReason = data
    }
  },
  validations: {
    cancelReason: {
      reason_choice_primary: {
        reasonsOk: (val, vm) => {
          // check to see if any secondary reasons are associated with the
          // primary; if they are, then require a secondary
          if (vm.secondary_required) {
            if (vm.reason_choice_secondary === null) {
              return false
            }
          }
          return val !== null && val.id !== null
        }
      },
      feedback: {
        required,
        minLength: minLength(1)
      }
    }
  }
}
</script>
