<!--
Collection button with preview images that links to the collection page.
Takes a collection object or collection ID as a prop.
-->
<template>
  <ImageTileButton
    :title="name ?? 'Collection'"
    :subtitle="itemCountText"
    :images="previewImageUrls"
    :image-count="2"
    type="router-link"
    :to="routeLocation"/>
</template>

<script setup>
import useCollection, { useCollectionById } from '@/composables/collection.js'
import ImageTileButton from '@/components/global/ImageTileButton.vue'

const props = defineProps({
  collection: {
    type: [Object, String],
    required: true
  }
})

const composable = typeof props.collection === 'string' ? useCollectionById : useCollection
const { name, itemCountText, previewImageUrls, routeLocation } = composable(() => props.collection)
</script>
