<template>
  <div class="row justify-content-center py-2 py-md-1 px-3">
    <div class="col col-12 login-body text-center buttons-container">
      <third-party-button
        v-if="inWebView()"
        @click="postSignUpWithApple">
        <template #logo>
          <div class="logo">
            <svg-apple
              height="32"
              width="32"/>
          </div>
        </template>
        <template #text>
          <span class="text">Sign up with Apple</span>
        </template>
      </third-party-button>
      <apple-button
        v-else
        @error="appleSignupError"
        @success="googleOrAppleLogin"/>

      <third-party-button
        v-if="inWebView()"
        class="mt-3"
        @click="postSignUpWithFacebook">
        <template #logo>
          <div class="logo">
            <svg-facebook
              height="20"
              width="20"/>
          </div>
        </template>
        <template #text>
          <span class="text">Sign up with Facebook</span>
        </template>
      </third-party-button>
      <facebook-button
        v-else
        class="mt-3"
        @facebookSignupError="facebookSignupError"
        @facebookConnected="completeFacebookSignup"/>

      <third-party-button
        v-if="inWebView()"
        class="mt-3"
        @click="postSignUpWithGoogle">
        <template #logo>
          <div class="logo">
            <svg-google
              height="20"
              width="20"/>
          </div>
        </template>
        <template #text>
          <span class="text">Sign up with Google</span>
        </template>
      </third-party-button>
      <div
        v-else
        ref="googleButtonWrapper">
        <google-button
          class="mt-3"
          :width="wrapperWidth"
          @error="googleSignupError"
          @success="googleOrAppleLogin"/>
      </div>

      <div class="row px-2 pt-3">
        <div class="col">
          <h5 class="pt-2">
            Or
          </h5>
        </div>
      </div>

      <base-form-errors :errors="formErrors"/>

      <form @submit.prevent="submit">
        <div class="d-flex justify-content-between mb-xs">
          <base-input
            v-model="firstName"
            :validations="v$.firstName"
            autocomplete="given-name"
            input-id="first-name"
            label="First name"/>
          <base-input
            v-model="lastName"
            class="ms-1"
            :validations="v$.lastName"
            autocomplete="family-name"
            input-id="last-name"
            label="Last name"/>
        </div>
        <div class="mb-xs">
          <base-input
            v-model="email"
            type="email"
            :validations="v$.email"
            autocomplete="email"
            input-id="email"
            label="Email"/>
        </div>
        <div class="mb-xs">
          <base-input
            v-model="password"
            type="password"
            :validations="v$.password"
            autocomplete="new-password"
            input-id="password"
            label="Password (6 character minimum)"/>
        </div>
        <div class="mb-xs">
          <base-input
            v-model="phone"
            type="tel"
            prepend="+1-"
            :validations="v$.phone"
            :cleave-options="{
              delimiter: '-',
              numericOnly: true,
              blocks: [3, 3, 4]}"
            label="Phone"/>
        </div>
        <base-checkbox
          v-model="consentToSMSMarketing"
          text="I agree to be contacted by SMS for marketing messages."
          class="mt-xs"
          :input-value="1"/>

        <div
          v-if="showPasswordResetPrompt"
          class="mb-3">
          <text-link
            :to="{ name: 'login'}"
            type="router-link">
            Sign in
          </text-link>
          <span class="small-text">
            or
          </span>
          <text-link
            type="a"
            href="/password_reset">
            reset your password.
          </text-link>
        </div>

        <sequin-button
          :disabled="formDisabled"
          block
          class="mt-2 mb-2">
          {{ buttonText }}
        </sequin-button>

        <div>
          <p class="fine-print text-center text-gray">
            By registering I hereby agree to the
            <text-link
              type="a"
              class="fine-print mb-0 py-0"
              variant="secondary"
              href="https://support.armoire.style/legal/"
              target="_blank">
              Terms of Service
            </text-link>
            <span>
              and agree to share my contact information
              with the Armoire team and be contacted
              by SMS or phone for account updates and/or
              marketing messages.
            </span>
          </p>
        </div>
      </form>

      <div
        v-if="!isEvent"
        class="pt-2">
        Already have an account?
        <text-link
          class="sign-in"
          type="a"
          href="/login/">
          Sign in
        </text-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { email, required, minLength, numeric, not } from '@vuelidate/validators'
import { phone } from '../global/vuelidate/customValidators'
import AppleButton from '../auth/AppleButton'
import FacebookButton from '../auth/FacebookButton'
import GoogleButton from '../auth/GoogleButton'
import ThirdPartyButton from '../auth/ThirdPartyButton.vue'
import SequinButton from '../global/sequin/SequinButton'
import TextLink from '../global/sequin/TextLink'
import useMobileApp, { MobileAppMessage, IncomingMobileAppMessage } from '@/composables/mobileApp.js'
import SvgApple from '@/components/global/svg/SvgApple.vue'
import SvgFacebook from '@/components/global/svg/SvgFacebook.vue'
import SvgGoogle from '@/components/global/svg/SvgGoogle.vue'
import BaseFormErrors from '@/components/global/BaseFormErrors.vue'
import BaseInput from '@/components/global/BaseInput.vue'
import BaseCheckbox from '@/components/global/BaseCheckbox.vue'

export default {
  components: {
    AppleButton,
    FacebookButton,
    GoogleButton,
    ThirdPartyButton,
    SequinButton,
    TextLink,
    SvgApple,
    SvgFacebook,
    SvgGoogle,
    BaseFormErrors,
    BaseInput,
    BaseCheckbox
  },
  props: {
    isEvent: {
      type: Boolean,
      default: false
    },
    formDisabled: {
      type: Boolean,
      default: false
    },
    formErrors: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const { inWebView, post, subscribeMessageListener } = useMobileApp()

    function postSignUpWithApple () {
      post(MobileAppMessage.SignUpWithApple)
    }
    function postSignUpWithFacebook () {
      post(MobileAppMessage.SignUpWithFacebook)
    }
    function postSignUpWithGoogle () {
      post(MobileAppMessage.SignUpWithGoogle)
    }
    function appleSignupError ({ error }) {
      if (error !== 'popup_closed_by_user') {
        emit('error', Array.isArray(error) ? error : [error])
      }
    }
    function facebookSignupError ({ message }) {
      emit('error', Array.isArray(message) ? message : [message])
    }
    function googleSignupError (error) {
      emit('error', Array.isArray(error) ? error : [error])
    }

    function googleOrAppleLogin (formData) {
      emit('sign-up', formData)
    }
    function completeFacebookSignup (formData) {
      formData.facebook_signup = true
      emit('sign-up', formData)
    }

    subscribeMessageListener(IncomingMobileAppMessage.OnAppleSignIn, (data) => {
      if (data.error) {
        appleSignupError(data.error)
      } else {
        googleOrAppleLogin(data)
      }
    })
    subscribeMessageListener(IncomingMobileAppMessage.OnFacebookSignIn, (data) => {
      if (data.error) {
        facebookSignupError(data.error)
      } else {
        completeFacebookSignup(data)
      }
    })
    subscribeMessageListener(IncomingMobileAppMessage.OnGoogleSignIn, (data) => {
      if (data.error) {
        googleSignupError(data.error)
      } else {
        googleOrAppleLogin(data)
      }
    })

    return {
      inWebView,
      postSignUpWithApple,
      postSignUpWithFacebook,
      postSignUpWithGoogle,
      appleSignupError,
      facebookSignupError,
      googleSignupError,
      googleOrAppleLogin,
      completeFacebookSignup,
      v$: useVuelidate()
    }
  },
  data () {
    return {
      chkDjangoPasswordTimer: null,
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      phone: '',
      wrapperWidth: 0,
      consentToSMSMarketing: true
    }
  },
  computed: {
    buttonText () {
      return this.isEvent ? 'Create account' : 'Take quiz + access closet'
    },
    showPasswordResetPrompt () {
      return this.formErrors.some(error => error.includes('already in use'))
    }
  },
  validations () {
    return {
      firstName: {
        required
      },
      lastName: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        phone
      },
      password: {
        required,
        notNumeric: not(numeric),
        minLength: minLength(6),
        djangoPasswordValidation (val) {
          if (val.length < 6) return true

          return new Promise((resolve) => {
            // debounce the Django call to avoid spamming API calls
            if (this.chkDjangoPasswordTimer) {
              clearTimeout(this.chkDjangoPasswordTimer)
              this.chkDjangoPasswordTimer = null
            }

            this.chkDjangoPasswordTimer = setTimeout(async () => {
              try {
                const res = await this.validatePassword(val)
                resolve(res.data.result)
              } catch (err) {
                // assume it's fine; if it's bad, it'll be caught
                // on the signup call
                resolve(true)
              }
            }, 250)
          })
        }
      }
    }
  },
  mounted () {
    if (!this.inWebView()) {
      this.updateWrapperWidth()
      window.addEventListener('resize', this.updateWrapperWidth)
    }
  },
  beforeUnmount () {
    if (!this.inWebView()) {
      window.removeEventListener('resize', this.updateWrapperWidth)
    }
  },
  methods: {
    ...mapActions('account', [
      'validatePassword'
    ]),
    updateWrapperWidth () {
      this.wrapperWidth = this.$refs.googleButtonWrapper.clientWidth || 0
    },
    async waitForValidation () {
      return new Promise((resolve) => {
        this.$watch(() => !this.v$.$pending, (isNotPending) => {
          if (isNotPending) {
            resolve(!this.v$.$invalid)
          }
        }, { immediate: true })
      })
    },
    async submit () {
      const isValid = await this.waitForValidation()
      // TODO - Need Error message about what failed - like empty phone number or name with invalid chars
      if (!isValid) return

      const formData = {
        email: this.email,
        password: this.password,
        firstName: this.firstName,
        lastName: this.lastName,
        main_phone: this.phone,
        consent_to_sms_marketing: this.consentToSMSMarketing
      }

      if (this.$route.query.type) {
        formData.initialPlanTypeIntent = this.$route.query.type
      }
      this.$emit('sign-up', formData)
    }
  }

}
</script>

<style lang="scss" scoped>
:deep(.form-group) {
  margin-top: 0;
}

.login-body {
  max-width: 425px;
}

.sign-in {
  font-size: $font-size-base;
}

.buttons-container {
  padding: 0 0 !important;
  max-width: 313px;

  @media (min-width: 576px) {
    max-width: 400px;
  }
}

</style>
