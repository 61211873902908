<template>
  <ul
    class="ps-4"
    :class="{'mobile-font' : isMobile}">
    <li v-if="color">
      {{ color }}
    </li>
    <li v-if="materials && materials.length">
      {{ formattedMaterialsList(materials) }}
    </li>
    <li v-if="fitType">
      {{ fitType }}
    </li>
    <li v-if="inseam.length">
      Available Inseam{{ inseam.length > 1 ? 's:': ':' }}
      {{ inseam.join(", ") }}
    </li>
    <li v-if="rise">
      Rise: {{ rise }} inches
    </li>
    <li v-if="price">
      ${{ price }} value
    </li>
    <li v-if="handmadeGarment">
      {{ handmadeGarment }}
    </li>
    <li v-if="patternVariation">
      {{ patternVariation }}
    </li>
    <li v-if="beltRemoved">
      {{ beltRemoved }}
    </li>
    <li v-if="maxPrice">
      <span
        v-if="maxPrice">
        <span
          v-if="sale && styleColor.saleEligible"
          class="sale"><b>SALE</b>:</span>
        Make it mine for
        <span
          v-if="fetchingPricing">{{ lastPrice }}
        </span>
        <span
          v-else-if="selectedItemTypePrice"><b>{{ formatPrice(selectedItemTypePrice, true) }}</b>
        </span>
        <span
          v-else>
          <b>
            <span
              v-if="hasPriceRange">${{ minPrice }} - </span>${{ maxPrice }}
          </b>
        </span>
        <span
          v-if="selectedItemTypePercentOff > 0"
          class="percent-off small-text me-1"> - {{ selectedItemTypePercentOff }}% off
        </span>
        <span
          v-else-if="!hasPriceRange && maxPercentOff > 0"
          class="percent-off small-text me-1"> - {{ maxPercentOff }}% off
        </span>
        <sequin-tooltip icon-class="">
          <template v-if="!selectedItemTypePrice && hasPriceRange">
            <h6>Why is there a price range?</h6>
            There are multiple items available. Each item is priced based on its
            rental history.
            <span v-if="styleColor.canPurchase">Select a size</span>
            <span v-else>Rent this style</span> to see final pricing and purchase at a discount.
          </template>
          <div
            v-else-if="styleColor.purchaseAnytime && !styleColor.rentable">
            Purchase at our members-only discounted price.
          </div>
          <div
            v-else>
            Rent this style
            <span v-if="itemCanBuyNow">or</span>
            <span v-else>to</span>
            purchase at our members-only discounted price.
          </div>
        </sequin-tooltip>
      </span>
      <template v-if="!fetchingPricing && !(selectedItemTypePercentOff > 0)">
        <br v-if="hasPriceRange">
        <span
          v-if="hasPriceRange && minPercentOff > 0 && maxPercentOff > 0"
          class="percent-off small-text">{{ minPercentOff }}-{{ maxPercentOff }}% off
        </span>
      </template>
      <br>
      <span
        v-if="sale && sale.ends"
        class="sale-text fine-print"><i>Sale ends {{ toMonthDayString(sale.end) }}</i></span>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { StyleColorMixin } from '../styleColorSet/StyleColorMixin'
import { formatPrice, toMonthDayString } from '@/utils/stringParsing.js'
import { ItemTypeStatusMixin } from './ItemTypeStatusMixin'
import SequinTooltip from '../global/sequin/SequinTooltip.vue'
import formatting from '../global/mixins/formatting.js'

export default {
  components: { SequinTooltip },
  mixins: [
    StyleColorMixin, ItemTypeStatusMixin, formatting
  ],
  props: {
    selectedItemType: {
      type: Object,
      default: null
    },
    styleColor: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      fetchingPricing: false,
      lastPrice: '...'
    }
  },
  computed: {
    ...mapState('closet', [
      'sale',
      'itemTypePricing'
    ]),
    ...mapState('client', [
      'isAuthenticated'
    ]),
    clientItemTypeStatus () {
      return this.getClientItemTypeStatus(this.selectedItemType)
    },
    selectedItemTypePrice () {
      if (!this.selectedItemType) {
        return null
      }
      // if the member has an item in their possession, we want to show the
      // pricing of that particular item rather than the cheapest item,
      // since that's what the member is purchasing.  This item in their
      // possession may be the cheapest, but if we have multiple items in
      // inventory and they are newer, then it's probably not the cheapest.
      const purchaseItem = this.getItemToPurchase(this.selectedItemType)
      if (purchaseItem) {
        return purchaseItem.item.priceClient * 100
      }
      if (!(this.selectedItemType.id in this.itemTypePricing)) {
        return null
      }
      return this.itemTypePricing[this.selectedItemType.id].client
    },
    selectedItemTypePercentOff () {
      if (!this.selectedItemTypePrice) {
        return null
      }
      return Math.round((1 - (this.selectedItemTypePrice / 100) / this.price) * 100)
    }
  },
  watch: {
    selectedItemType: async function (itemType, oldItemType) {
      // `selectedItemType` can be unset when opening the ClosetDetailFlyout directly from itself
      // to a product listing where we don't automatically select a size.
      if (!itemType) {
        return
      }
      if (this.isAuthenticated) {
        if (!(itemType.id in this.itemTypePricing)) {
          if (oldItemType && oldItemType.id && oldItemType.id in this.itemTypePricing) {
            this.lastPrice = formatPrice(this.itemTypePricing[oldItemType.id].client, true)
          }
          this.fetchingPricing = true
          try {
            await this.getItemTypePrice(this.selectedItemType)
          } catch (err) {
            this.lastPrice = '...'
          }
          this.fetchingPricing = false
        }
      }
    }
  },
  mounted () {
    if (this.isAuthenticated) {
      this.getPriceRange({
        styleColorId: this.styleColor.id,
        sizes: this.styleColor.itemTypes.filter(x => x.numAvailable).map(x => x.trueSize)
      })
    }
  },
  methods: {
    ...mapActions('closet', [
      'getPriceRange'
    ]),
    ...mapActions('client', [
      'getItemTypePrice'
    ]),
    toMonthDayString,
    formatPrice
  }
}
</script>

<style lang="scss" scoped>
li {
  font-size: $small-text-font-size;
}

.value {
  color: $pewter;
}

.sale {
  color: $success-light;
}
.sale-text {
  color: $pewter;
}
.percent-off {
  color: $pewter;
}

:deep(.tippy) {
  display: inline-block;
}

.mobile-font {
  li {
  font-size: 16px !important;
  }
}

</style>
