<template>
  <third-party-button
    @click="loginWithApple">
    <template #logo>
      <div class="logo">
        <svg-apple
          height="32"
          width="32"/>
      </div>
    </template>
    <template #text>
      <div class="text">
        {{ variant === 'login' ? 'Continue' : 'Sign up' }} with Apple
      </div>
    </template>
  </third-party-button>
</template>

<script>
import { mapActions } from 'vuex'
import ThirdPartyButton from './ThirdPartyButton'
import SvgApple from '../global/svg/SvgApple.vue'

export default {
  components: { ThirdPartyButton, SvgApple },
  props: {
    variant: {
      type: String,
      default: 'signup',
      validator: value => {
        return value.match(/(signup|login)/)
      }
    }
  },
  created () {
    if (typeof AppleID === 'undefined') {
      const script = document.createElement('script')
      script.id = '_sign-in-with-apple-script'
      script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
      script.type = 'text/javascript'
      script.onload = function () {
        // eslint-disable-next-line
        AppleID.auth.init({
          clientId: 'style.armoire',
          scope: 'name email',
          redirectURI: `https://${window.location.hostname}/`,
          usePopup: true // or false defaults to false
        })
      }
      document.head.appendChild(script)
    }
  },
  methods: {
    ...mapActions('auth', [
      'signup'
    ]),
    async loginWithApple () {
      try {
        // eslint-disable-next-line
        const data = await AppleID.auth.signIn()
        const formData = {
          apple_token: data.authorization.id_token,
          code: data.authorization.code
        }
        // fields below are only present in initial login/signup
        if (Object.prototype.hasOwnProperty.call(data, 'user')) {
          formData.email = data.user.email
          formData.first_name = data.user.name.firstName
          formData.last_name = data.user.name.lastName
        }
        this.$emit('success', formData)
      } catch (error) {
        this.$emit('error', error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  padding-right: 10px;
}

</style>
