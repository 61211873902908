<template>
  <div v-if="membershipPlans">
    <header
      v-if="showHeader"
      class="bg-white fixed-top">
      <nav
        class="navbar navbar-expand-md navbar-light navbar-header align-items-center"
        role="navigation"
        aria-label="Main menu">
        <div class="d-flex align-items-center py-2">
          <!-- Mobile text -->
          <router-link
            :to="isAuthenticated ? { path: '/home' } : { name: 'login' }"
            class="navbar-brand p-2 me-0">
            <img
              class="m-0 h-auto"
              alt="Armoire"
              :class="!isMobile && 'ms-2'"
              :width="isMobile ? '116' : '140'"
              height="auto"
              :src="logoSource">
          </router-link>
        </div>
      </nav>
    </header>
    <h4>Choose Your Membership</h4>
    <p class="text-pewter small-text">
      All Armoire memberships include free shipping both ways, dry cleaning, and personal styling.
    </p>
    <div
      class="p-2 my-4 pb-5"
      :class="{ 'membership-desktop': !isMobile }">
      <div class="row">
        <plain-button
          v-for="membershipPlan in membershipPlans.filter(plan => !plan.prepayment)"
          :key="membershipPlan.id"
          class="col py-2 px-lg-1"
          @click="selectPlan(membershipPlan.id, true)">
          <plan-card
            :plan="membershipPlan"
            :selected="membershipPlan.id === membershipPlanSelectedId"
            :header-text="(!membershipPlan.prepayment && membershipPlan.shipmentsPerInterval === null) ? 'Best Value' : ''"/>
        </plain-button>
      </div>
    </div>
    <div class="bottom-nav justify-content-center">
      <sequin-button
        :disabled="membershipPlanSelected === null"
        type="router-link"
        :to="{ name: 'subscribe' }"
        class="my-3 w-330"
        :class="{ 'mb-4': isMobile }">
        Next
      </sequin-button>
    </div>
  </div>
</template>

<script>
import PlainButton from '../global/sequin/PlainButton'
import PlanCard from './PlanCard'
import SequinButton from '../global/sequin/SequinButton'
import { mapState, mapActions, mapGetters } from 'vuex'
import BaseNavigationMixin from '../global/BaseNavigationMixin'
import { MobileAppMessage, inMobileWebView, postToMobileWebView } from '@/utils/mobileApp.js'

export default {
  components: {
    PlainButton,
    PlanCard,
    SequinButton
  },
  mixins: [BaseNavigationMixin],
  computed: {
    ...mapState('subscribe', [
      'membershipPlanSelectedId',
      'membershipPlans'
    ]),
    ...mapGetters('subscribe', [
      'membershipPlanSelected'
    ]),
    showHeader () {
      return !inMobileWebView()
    }
  },
  created () {
    if (typeof Stripe === 'undefined') {
      // load the Stripe library (once), the first time the user enters the
      // Subscribe pipeline.  Takes ~300-400ms on a 3G connection, so this
      // will be done a long time before it's actually needed (in
      // SubscribePayment)
      const script = document.createElement('script')
      script.id = '_stripe-checkout-script'
      script.src = 'https://js.stripe.com/v3/'
      script.type = 'text/javascript'
      document.head.appendChild(script)
    }
  },
  updated () {
    // Make sure the default selected plan is attached to the client
    // On resubscribe, the data may not be loaded yet.
    if (this.membershipPlanSelectedId) {
      this.selectPlan(this.membershipPlanSelectedId)
    }
  },
  async mounted () {
    postToMobileWebView(MobileAppMessage.SubscribeLoaded)

    if (this.membershipPlans && this.membershipPlans.length === 1) {
      if (!this.membershipPlanSelected) {
        await this.selectPlan(this.membershipPlans[0].id)
      }
    }
  },
  methods: {
    ...mapActions('subscribe', [
      'selectMembershipPlan'
    ]),
    async selectPlan (planId, trackEvent = false) {
      await this.selectMembershipPlan({ planId, trackEvent })
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: $default-border;
  z-index: $zindex-fixed;
  background: $white;
}
.membership-desktop {
  width: 80%;
  margin: 0 auto;
}

</style>
