<!-- Tracking button used to start the style quiz -->
<template>
  <TrackingButton
    :app-entry-point="appEntryPoint"
    :event-label="`${toTitleCase(location)} Get Started`"
    :text="text"
    target="_self"
    :to="styleQuizUrl"/>
<!-- We override the default target to be _self so style quiz (website router link) opens in the same tab  -->
</template>

<script setup>
import TrackingButton from '@shared/components/ADORN/TrackingButton.vue'
import { toTitleCase } from '@/utils/stringParsing.js'
import { useStyleQuizUrl } from '@/homepage/composables/styleQuizUrl.js'

defineProps({
  appEntryPoint: {
    type: String,
    default: 'homepage'
  },
  location: {
    type: String,
    required: true
  },
  text: {
    type: String,
    default: 'Get Started'
  }
})

const { styleQuizUrl } = useStyleQuizUrl()
</script>
