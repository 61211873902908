<!-- Button that can display up to 2 images along with a title and optional subtitle -->
<template>
  <PlainButton class="tile-button position-relative p-0">
    <div :class="['images', `images-${imageCount}`]">
      <PreviewImage
        v-for="image in images.slice(0, Math.min(images.length, imageCount, maxImageCount))"
        :key="image"
        :src="image"/>
    </div>
    <div class="overlay">
      <TextLineLimit
        ref="titleLine"
        :line-limit="lineLimit"
        :allow-tooltip="true"
        tag="h4">
        {{ normalizeText(title) ?? '&nbsp;' }}
      </TextLineLimit>
      <TextLineLimit
        v-show="showSubtitle"
        :line-limit="lineLimit"
        :allow-tooltip="true"
        tag="div"
        class="small-text text-white">
        {{ normalizeText(subtitle) ?? '&nbsp;' }}
      </TextLineLimit>
    </div>
  </PlainButton>
</template>

<script setup>
import { computed, ref } from 'vue'
import PlainButton from '@/components/global/sequin/PlainButton.vue'
import PreviewImage from '@shared/components/ADORN/PreviewImage.vue'
import TextLineLimit from '@/components/global/sequin/TextLineLimit.vue'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  subtitle: {
    type: String,
    default: null
  },
  images: {
    type: Array,
    default: () => []
  },
  imageCount: {
    type: Number,
    default: 1,
    validator: value => value > 0 && value <= 2
  },
  truncateText: {
    type: Boolean,
    default: true
  }
})
const maxImageCount = 2
const lineLimit = computed(() => props.truncateText ? 1 : 'none')

const titleLine = ref(null)
const titleLineCount = computed(() => titleLine.value?.lineCount ?? 1)
const showSubtitle = computed(() => titleLineCount.value === 1)

function normalizeText (text) {
  const trimmed = text?.trim()
  return trimmed?.length > 0 ? trimmed : null
}
</script>

<style lang="scss" scoped>
@import './ImageTileButton.scss';

.tile-button {
  .images {
    display: flex;

    &-1 {
      .preview-image {
        width: 100%;
        &, :deep(img) {
          aspect-ratio: #{$image-aspect-ratio};
        }
      }
    }
    &-2 {
      .preview-image {
        width: 50%;
      }
    }

    .preview-image {
      border-radius: 0px;
    }
    // 2 images.
    .preview-image:first-child:nth-last-child(2),
    .preview-image:first-child:nth-last-child(2) ~ .preview-image {
      width: 50%;
    }
  }
}
</style>
