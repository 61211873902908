<template>
  <div
    class="form-group text-start mb-0"
    :class="$attrs.class">
    <!-- Allows scrolling to error on long forms -->
    <div :class="{ 'error-scroll': error }"/>

    <div class="input-group">
      <div
        v-if="prepend"
        class="input-group-prepend">
        <span
          id="basic-addon1"
          class="input-group-text">
          {{ prepend }}
        </span>
      </div>
      <label
        :class="{ error, 'has-float-label': hasFloatLabel }">
        <input
          v-bind="$attrs"
          ref="input"
          :class="{ error, 'read-only': readOnly }"
          :placeholder="label"
          :value="modelValue"
          :readonly="readOnly"
          class="form-control px-3"
          @input="onInput"
          @blur="validations.$touch()">
        <span
          class="placeholder"
          :class="{ error }">
          {{ label }}
        </span>
      </label>
    </div>
    <div
      v-if="error || helperText"
      class="error-slot">
      <base-errors
        v-if="error"
        :validations="validations"/>
      <span v-else>
        {{ helperText }}
      </span>
    </div>
  </div>
</template>

<script>
import BaseErrors from './BaseErrors'
import Cleave from 'cleave.js'

export default {
  compatConfig: {
    COMPONENT_V_MODEL: false
  },
  components: {
    BaseErrors
  },
  inheritAttrs: false,
  props: {
    cleaveOptions: {
      type: Object,
      default: null
    },
    focusOnLoad: {
      type: Boolean,
      default: false
    },
    helperText: {
      type: String,
      default: null
    },
    hasFloatLabel: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: null
    },
    prepend: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Object,
      default: () => {
        return { $touch () {} }
      }
    },
    modelValue: {
      type: [String, Number],
      default: null
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      cleave: null
    }
  },
  computed: {
    error () {
      return this.validations.$error
    }
  },
  mounted () {
    if (this.cleaveOptions) {
      this.cleave = new Cleave(
        this.$el.querySelector('input'),
        this.getOptions(this.cleaveOptions))
      this.cleave.setRawValue(this.modelValue)
    }

    if (this.focusOnLoad) {
      this.$refs.input.focus()
    }
  },
  unmounted () {
    if (this.cleave) this.cleave.destroy()
  },
  methods: {
    onInput (e) {
      this.$emit('update:modelValue', e.target.value)
    },
    getOptions (options) {
      // getOptions/onValueChanged derived from:
      // https://github.com/ankurk91/vue-cleave-component/blob/master/src/component.js
      // Preserve original callback
      this.onValueChangedFn = options.onValueChanged
      return Object.assign({}, options, {
        onValueChanged: this.onValueChanged
      })
    },
    onValueChanged (event) {
      const value = this.raw ? event.target.rawValue : event.target.value
      this.$emit('update:modelValue', value)

      // Call original callback method
      if (typeof this.onValueChangedFn === 'function') {
        this.onValueChangedFn(event)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .error-slot {
    color: #979797;
    font-size: 0.8rem;
    line-height: 0.8rem;
    margin: 0.1rem 0 0.2rem 0.7rem;
    min-height: 1rem;
    padding: 0;
  }
  input {
    border: 1px solid $dark;
    border-radius: 2px;
    &:focus {
      border: 2px solid $secondary;
      box-shadow: none;
    }
    &.error {
      border: 2px solid red;
      color: red;
    }
  }
  .placeholder {
    margin-top: .15em;
    color: #979797;
    font-weight: 400;
    &.error {
      color: red;
    }
  }
  label:focus-within:not(.error) .placeholder {
    color: $secondary
  }

  .read-only {
    background-color: $white;
    color: $pewter;
  }

  label {
    flex: 1;
  }

  // Floating labels: https://codepen.io/tonystar/pen/LRdpYZ
  .has-float-label {
      display: block;
      position: relative
  }
  .has-float-label label,
  .has-float-label>span {
      position: absolute;
      cursor: text;
      font-size: 75%;
      opacity: 1;
      -webkit-transition: all .2s;
      transition: all .2s;
      top: -.65em;
      left: .75rem;
      white-space: nowrap;
      z-index: 3;
      line-height: 1;
      padding: 0 1px
  }
  .has-float-label label::after,
  .has-float-label>span::after {
      content: " ";
      display: block;
      position: absolute;
      background: #fff;
      height: 4px;
      top: 50%;
      left: -.2em;
      right: -.2em;
      z-index: -1
  }
  .has-float-label .form-control::-webkit-input-placeholder {
      opacity: 0;
      -webkit-transition: all .2s;
      transition: all .2s
  }
  .has-float-label .form-control::-moz-placeholder {
      opacity: 0;
      transition: all .2s
  }
  .has-float-label .form-control:-ms-input-placeholder {
      opacity: 0;
      transition: all .2s
  }
  .has-float-label .form-control::placeholder {
      opacity: 0;
      -webkit-transition: all .2s;
      transition: all .2s
  }
  .has-float-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
      opacity: 0
  }
  .has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
      opacity: 0
  }
  .has-float-label .form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
      opacity: 0
  }
  .has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
      opacity: 0
  }
  .has-float-label .form-control:placeholder-shown:not(:focus)+* {
      font-size: 100%;
      opacity: 1;
      top: .5em
  }
  .input-group .has-float-label {
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      margin-bottom: 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center
  }
  .input-group .has-float-label .form-control {
      width: 100%;
      border-radius: .25rem
  }
  .input-group .has-float-label:not(:last-child),
  .input-group .has-float-label:not(:last-child) .form-control {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: 0
  }
  .input-group .has-float-label:not(:first-child),
  .input-group .has-float-label:not(:first-child) .form-control {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0
  }
</style>
