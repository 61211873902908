<template>
  <div>
    <div class="hero-wrapper">
      <h1
        class="title-hero">
        Refer a Friend
      </h1>
      <img
        class="friends-hero"
        alt=""
        src="https://d2ezpplblfgpjx.cloudfront.net/refer-friends-mobile.png">
    </div>
    <div class="info-hero text-center p-4">
      <h4>{{ header }}</h4>
      <div
        :class="{'expanded': expanded}"
        class="details">
        Share Armoire with your friends and network!. As a thank you, you'll
        get ${{ referrerCredit }} subscription credit for every friend who signs
        up using your link or code. They'll get <b>our best deal on their first
          month of Armoire</b><span v-if="refereeBonusItems > 0">, plus
          {{ refereeBonusItems }} free bonus items in their first case</span>!
      </div>
      <text-link @click="handleClick">
        Details
      </text-link>
    </div>
  </div>
</template>

<script>
import TextLink from '../global/sequin/TextLink'

export default {
  name: 'ReferralBannerMobile',
  components: { TextLink },
  props: {
    header: {
      type: String,
      required: true
    },
    referrerCredit: {
      type: Number,
      required: true
    },
    refereeBonusItems: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      expanded: false
    }
  },
  methods: {
    handleClick () {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss" scoped>
  .info-hero {
    background-color: $origami;
    margin-left: -15px;
    margin-right: -15px;
  }

  .hero-wrapper {
    position: relative;
    text-align: center;
    margin-left: -15px;
  }

  .title-hero {
    position: absolute;
    margin: 0;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    z-index: 2;
  }

  .friends-hero {
    width: 100vw;
  }

  .details {
    max-height: 0;
    overflow-y: hidden;
    @include transition-base-all;
    &.expanded {
      max-height: 100vh;
    }
  }
</style>
