<template>
  <BaseButton
    ref="button"
    @click="onClick"/>
</template>

<script setup>
import { ref } from 'vue'
import useAnalytics from '@shared/composables/analytics.js'
import BaseButton from '@shared/components/ADORN/BaseButton.vue'
import { captureException } from '@sentry/vue'

const props = defineProps({
  appEntryPoint: {
    type: String,
    required: true,
    validator: value => ['homepage', 'website'].includes(value)
  },
  eventLabel: {
    type: String,
    required: true
  }
})

const emit = defineEmits({
  click: (event) => event instanceof MouseEvent
})

const { trackClickEvent } = useAnalytics()

const button = ref(null)
async function onClick (event) {
  const { isHyperlink, isExternalLink, activate } = button.value.link
  const preventDefault = isHyperlink.value && !isExternalLink.value
  try {
    if (preventDefault) {
      // If an href is present, we'll prevent the navigation
      // until we have a chance to track the click event.
      event.preventDefault()
    }
    await trackClickEvent(props.appEntryPoint, props.eventLabel)
    emit('click', event)
  } catch (error) {
    captureException(error)
  } finally {
    if (preventDefault) {
      activate()
    }
  }
}
</script>
