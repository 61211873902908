<template>
  <accordion-with-header
    :expanded="expanded"
    title="Support"
    @accordion-click="e => $emit('accordion-click', e)">
    <div class="mt-2">
      <p class="mb-1">
        Still have questions? View the full <text-link
          type="a"
          size="paragraph"
          :href="FAQ_URL"
          target="_blank">
          FAQ
        </text-link> or <text-link
          size="paragraph"
          @click="contactSupport">
          Contact support
        </text-link>.
      </p>
    </div>
  </accordion-with-header>
</template>

<script>
import AccordionWithHeader from '../../global/sequin/AccordionWithHeader.vue'
import TextLink from '../../global/sequin/TextLink.vue'
import ReturnsMixin from './ReturnsMixin'
export default {
  components: {
    AccordionWithHeader,
    TextLink
  },
  mixins: [ReturnsMixin],
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    contactSupport () {
      window.Intercom('showNewMessage')
    }
  }
}
</script>
