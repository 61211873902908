import axios from './config'
import store from '../index'

// AUTH
const apiSubscribe = {
  getSubscriptionQuote (data) {
    const username = store.state.client.username
    return axios.post('/api/clients/' + username + '/subscription_quote/', data)
  },
  getPlans (promoOverride) {
    let queryParams = ''
    if (promoOverride) {
      queryParams += '?promo_code=' + promoOverride
    }
    return axios.get('/api/plans/' + queryParams)
  },
  subscribeClient (data) {
    const username = store.state.client.username
    return axios.post('/api/clients/' + username + '/subscribe_client/', data)
  },
  getReferralCampaign () {
    return axios.get('/api/referralcampaigns/')
  },
  getQuote (planId) {
    return axios.get('/api/plans/' + planId + '/quote/')
  },
  changePlan (planId, data) {
    return axios.post('/api/plans/' + planId + '/subscribe/', data)
  },
  validateReferralCode (code) {
    return axios.get('/api/clients/?referralCode=' + code)
  },
  getCancelReasons () {
    return axios.get('/api/cancelreasons/')
  }
}

export default apiSubscribe
