<template>
  <div class="bg-origami">
    <div
      class="bg-white shadow my-md p-xl p-md-lg col-12 col-lg-10"
      :class="{'offset-1': !isTabletOrSmaller}">
      <div class="row">
        <div
          class="col-12 col-md-8"
          :class="{'text-center': isMobile}">
          <h4 class="mb-lg-sm mb-md">
            Need help? We'll find the perfect style.
          </h4>
          <p class="mb-lg-0 mb-md">
            Send us a quick note and we'll curate extra options based on your request.
          </p>
        </div>
        <div class="col-12 col-md-4 d-flex align-items-center">
          <BaseButton
            variant="stylist"
            class="stylist whitespace-no-wrap"
            text="Chat With a Stylist"
            @click="onClick"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseButton from '@shared/components/ADORN/BaseButton'
import useScreenSize from '@shared/composables/screenSize'

const { isMobile, isTabletOrSmaller } = useScreenSize()
function onClick () {
  window.Intercom('showNewMessage')
}
</script>
