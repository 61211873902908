<!-- Basic pill component that can act as a radio, checkbox, or just display a value -->
<template>
  <label :class="['base-pill', type, { disabled, checked }]">
    <span>{{ text }}</span>
    <input
      v-if="type !== 'display'"
      v-model="_modelValue"
      :type="type"
      :value="value"
      :disabled="disabled"
      :checked="checked"
      :name="name">
  </label>
</template>

<script setup>
import { computed } from 'vue'

defineOptions({
  compatConfig: {
    COMPONENT_V_MODEL: false
  }
})

const props = defineProps({
  type: {
    type: String,
    default: 'display',
    validator: (value) => ['display', 'radio', 'checkbox'].includes(value)
  },
  modelValue: {
    type: [String, Array, Boolean],
    required: true,
    validator: (value) => Array.isArray(value)
      ? value.every((v) => ['string', 'boolean'].includes(typeof v))
      : ['string', 'boolean'].includes(typeof value)
  },
  value: {
    type: String,
    required: true
  },
  text: {
    type: String,
    required: true
  },
  disabled: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    default: null
  }
})

// Move this to the name prop validator when we upgrade to Vue 3.4+
if (props.type === 'radio' && !props.name) {
  // eslint-disable-next-line no-console
  console.warn('The name prop is required when using the radio type')
}

const emit = defineEmits({
  'update:modelValue': (value) => ['string', 'boolean'].includes(typeof value) || Array.isArray(value)
})

const _modelValue = computed({
  get () { return props.modelValue },
  set (value) { emit('update:modelValue', value) }
})
const checked = computed(() =>
  !props.disabled &&
  props.type !== 'display' &&
  (Array.isArray(props.modelValue)
    ? props.modelValue.includes(props.value)
    : props.modelValue === props.value))
</script>

<style lang="scss" scoped>
.base-pill {
  border: 0.5px solid $pewter;
  border-radius: $sequin-border-radius;
  padding: $spacing-xs $spacing-sm;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  background-color: $white;
  color: $armor;
  font-family: $body-font-family;
  font-size: 14px;
  font-style: normal;
  font-weight: $font-weight-normal;
  line-height: 20px;
  white-space: nowrap;
  user-select: none;

  &:hover:not(.disabled):not(.display) {
    background-color: rgba($plum, 0.75);
    color: $white;
  }

  &:focus, &:focus-within {
    outline: none;
    box-shadow: 0 0 0 1px $plum;
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
  }

  &.display {
    background-color: $white;
    cursor: default;
  }

  &.checked {
    background-color: $plum;
    color: $white;
  }

  &.disabled:not(.display) {
    color: $ash;
    cursor: not-allowed;
    position: relative;
    overflow: hidden;
    border-color: $ash;

    // Diagonal line from bottom left to top right.
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 110%;
      height: 0.5px;
      background: $ash;
      transform: rotate(-20deg);
    }
  }
}
</style>
