<template>
  <div
    v-if="reviewItem"
    class="container-fluid">
    <div
      v-if="isMobile"
      class="row">
      <ActionHeader
        page-header>
        {{ headerText }}
        <template #rightHeaderSlot>
          <TextLink
            v-if="canSkipReviews"
            @click="completeNextReviewAction">
            Skip
          </TextLink>
        </template>
      </ActionHeader>
    </div>
    <div
      v-else
      class="d-flex offset-md-1 align-items-center column-gap-lg py-sm">
      <BackButton
        v-if="currentReviewItemIndex === 0"
        with-border
        :back-default-route="{ name: 'review-select' }"/>
      <h2 class="m-0 flex-grow-1">
        {{ headerText }}
      </h2>
      <TextLink
        v-if="canSkipReviews"
        class="align-self-end"
        @click="completeNextReviewAction">
        Skip
      </TextLink>
    </div>
    <div
      :class="isMobile ? 'row' : 'd-flex'"
      class="align-items-start mt-md-lg column-gap-md-lg">
      <ReviewItemHeaderCard
        :package-item="reviewItem"
        class="col-md-4 offset-md-1"/>
      <div class="d-flex flex-column col-md-6">
        <ReviewItemSection
          title="General"
          :required="true"
          class="pt-md-0">
          <div>
            <h5>
              Overall, how did you feel about this style?<span class="required-asterisk">*</span>
            </h5>
            <StarRatingScale
              v-model="localReview.overallStarRating"
              :validations="v$.localReview.overallStarRating"
              class="star-rating"
              @rating-selected="localReview.overallStarRating = $event"/>
            <BaseErrors
              :class="{ 'error-scroll': v$.localReview.overallStarRating.$error }"
              :validations="v$.localReview.overallStarRating"/>
          </div>
          <ReviewItemNumWears
            v-model="localReview"
            :validations="v$.localReview.nWears"/>
          <ReviewItemOccasions
            v-if="parseInt(localReview.nWears) > 1"
            :review="localReview"/>
        </ReviewItemSection>
        <ReviewItemSection title="Fit">
          <ReviewItemFit
            v-model="localReview"
            :item="reviewItem.item"
            :validations="v$.localReview"/>
          <ReviewItemSizing
            v-model="localReview.sizingRating"
            :validations="v$.localReview.sizingRating"/>
          <ReviewItemMaternityFriendly
            v-if="showMaternityFriendlySection"
            v-model="localReview"
            :validations="v$.localReview.maternityFriendly"/>
        </ReviewItemSection>
        <ReviewItemSection title="Condition">
          <ReviewItemQuality
            v-model="localReview"
            :item="reviewItem.item"
            :validations="v$.localReview"/>
        </ReviewItemSection>
        <ReviewItemSection title="Additional Feedback">
          <ReviewImageUpload
            :review="localReview"
            :validations="v$.localReview.itemFeedbackPhotos"
            :image-upload-button-text="imageUploadButtonText"
            @photos-changed="localReview.itemFeedbackPhotos = $event"/>
          <div>
            <p>Like reading reviews? Tell others what you thought and how this item fit.</p>
          </div>
          <ReviewFeedback
            :review="localReview"
            :validations="v$.localReview"
            :placeholder="feedbackTextPlaceholder"/>
        </ReviewItemSection>
        <ReviewItemExpertReview
          v-if="isStaff"
          v-model="localReview"
          :validations="v$.localReview.expertReview"/>
        <div data-intercom-target="intercom-submit-reviews">
          <AppMessage
            v-if="error"
            variant="danger"
            class="my-3">
            <div>
              {{ error.toString() }}
            </div>
          </AppMessage>
          <BaseButtonAsync :func="onConfirm">
            <BaseButton
              class="w-100 my-lg"
              variant="primary"
              :text="buttonText"
              :disabled="v$.localReview.$invalid || submitting || invalidRequiredRichReview"/>
          </BaseButtonAsync>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import ReviewItemOccasions from './ReviewItemOccasions'
import ReviewItemFit from './ReviewItemFit'
import ReviewItemQuality from './ReviewItemQuality'
import ReviewFeedback from './ReviewFeedback'
import ReviewItemSizing from './ReviewItemSizing'
import ActionHeader from '../global/sequin/ActionHeader'
import BackButton from '../global/sequin/BackButton'
import BaseButton from '@shared/components/ADORN/BaseButton'
import BaseErrors from '@/components/global/BaseErrors.vue'
import BaseButtonAsync from '@/components/global/BaseButtonAsync.vue'
import AppMessage from '../global/sequin/AppMessage'
import TextLink from '../global/sequin/TextLink.vue'
import ReviewImageUpload from './ReviewImageUpload'
import ReviewItemHeaderCard from './ReviewItemHeaderCard'
import StarRatingScale from '@/components/global/sequin/StarRatingScale'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import goBack from '../global/mixins/goBack'

import ReviewItemNumWears from './ReviewItemNumWears'
import ReviewItemMaternityFriendly from './ReviewItemMaternityFriendly'
import ReviewItemExpertReview from './ReviewItemExpertReview'
import ReviewItemSection from './ReviewItemSection.vue'
import useScreenSize from '@shared/composables/screenSize'

export default {
  components: {
    ActionHeader: ActionHeader,
    AppMessage,
    BackButton,
    BaseButton,
    BaseButtonAsync,
    BaseErrors,
    ReviewFeedback,
    ReviewImageUpload,
    ReviewItemFit,
    ReviewItemExpertReview,
    ReviewItemMaternityFriendly,
    ReviewItemNumWears,
    ReviewItemOccasions,
    ReviewItemQuality,
    ReviewItemSection,
    ReviewItemSizing,
    ReviewItemHeaderCard,
    StarRatingScale,
    TextLink: TextLink
  },
  mixins: [goBack],
  props: {
    validations: {
      type: Object,
      default: () => {
        return { $touch () {} }
      }
    }
  },
  setup () {
    const v$ = useVuelidate()
    const { isMobile } = useScreenSize()

    return {
      v$,
      isMobile
    }
  },
  data () {
    return {
      localReview: {},
      submitting: false,
      error: null
    }
  },
  computed: {
    ...mapState('review', [
      'reviews',
      'reviewItems',
      'itemsToSwap',
      'deliveredReviewedItems',
      'canSkipReviews'
    ]),
    ...mapState('community', [
      'occasionTags'
    ]),
    ...mapState('closet', [
      'styleColorsMap',
      'mustReviewItems'
    ]),
    ...mapState('styleProfile', [
      'showMaternity',
      'showMaternityFriendly'
    ]),
    ...mapState('client', [
      'influencer',
      'isStaff',
      'tooManyItemsOut',
      'numReviewsWithPhoto',
      'numIncentivePhotosForBonusItem'
    ]),
    ...mapGetters('client', [
      'canSwapItems',
      'active'
    ]),
    headerText () {
      return `Review Item${this.reviewItems.length > 1 ? `s (${this.displayIndex})` : ''}`
    },
    canSwapDeliveredItems () {
      return this.canSwapItems && !this.tooManyItemsOut && this.active
    },
    currentReviewItemIndex () {
      return this.reviewItems.indexOf(this.reviewItem)
    },
    reviewItem () {
      return this.reviewItems.find(reviewItem => reviewItem?.id === this.$route.params.reviewItemId)
    },
    isFirstReview () {
      return this.currentReviewItemIndex === 0
    },
    isLastReview () {
      return this.currentReviewItemIndex === this.reviewItems.length - 1
    },
    buttonText () {
      if (!this.isLastReview) {
        return 'Submit and Review Next Item'
      }
      return 'Submit Review'
    },
    displayIndex () {
      return `${this.currentReviewItemIndex + 1}/${this.reviewItems.length}`
    },
    occasionTagsLoading () {
      return this.occasionTags === null || this.occasionTags.length === 0
    },
    showMaternityFriendlySection () {
      const style = this.styleColorsMap[this.reviewItem.item.styleColor].style
      return !style.maternity && !style.maternityFriendly && (
        this.showMaternity || this.showMaternityFriendly
      )
    },
    useSingleItemReviewFlow () {
      return !!this.$route.params.singleItem
    },
    richReviewRequired () {
      return this.isStaff || this.influencer
    },
    itemAlreadyReturned () {
      return this.mustReviewItems.some(i => i.id === this.reviewItem.id)
    },
    photoUploadRequired () {
      return this.richReviewRequired && !this.itemAlreadyReturned && this.localReview.nWears > 0
    },
    imageUploadButtonText () {
      return !this.photoUploadRequired ? 'Add a photo to your review.' : `Hi ${this.isStaff ? 'Employees' : 'Influencers'}! We are requiring a photo upload of your look or garment detail to better inform all Armoire members.`
    },
    feedbackTextPlaceholder () {
      return this.richReviewRequired ? `Hi ${this.isStaff ? 'Employees' : 'Influencers'}! We are requiring text reviews to provide insightful details for Armoire members.` : 'Like reading reviews? Tell others what you thought and how this item fit.'
    },
    currentPhotoUploaded () {
      return this.localReview.itemFeedbackPhotos ? this.localReview.itemFeedbackPhotos.length > 0 : null
    },
    validFeedbackMinText () {
      return this.localReview.qualityFeedbackText ? this.localReview.qualityFeedbackText.length >= 100 : null
    },
    validRichReview () {
      return this.photoUploadRequired ? (this.currentPhotoUploaded && this.validFeedbackMinText) : this.validFeedbackMinText
    },
    invalidRequiredRichReview () {
      return this.richReviewRequired && !this.validRichReview
    }
  },
  async mounted () {
    if (!this.reviewItems.length) {
      this.$router.push({ name: 'closet-sections' })
    }
    if (this.occasionTagsLoading) {
      await this.getOccasionTags()
    }
    this.localReview = {
      packageItemPk: this.reviewItem.id,
      itemId: this.reviewItem.item.id,
      itemtypeId: this.reviewItem.item.itemType,
      overallStarRating: null,
      nWears: '',
      feedbackText: '',
      feedbackTextPrivacyLevel: '',
      sizingRating: null,
      fitRating: '',
      fitRatingMadeItWork: '',
      size: [],
      tooBig: [],
      tooSmall: [],
      qualityIssue: false,
      qualityChoices: [],
      locationChoices: [],
      missingItemsChoices: [],
      damaged: false,
      worn: false,
      qualityFeedbackText: '',
      itemFeedbackPhotos: [],
      feedbackPhotosRequired: this.photoUploadRequired,
      occasionTags: this.occasionTags.map(t => {
        return { ...t, selected: false }
      }),
      maternityFriendly: null,
      expertReview: false,
      maternityFriendlySectionRequired: this.showMaternityFriendlySection,
      expertReviewSectionRequired: this.isStaff

    }
    this.$store.commit('review/SET_REVIEW', this.localReview)
  },
  methods: {
    ...mapActions('review', [
      'submitReviews',
      'submitReviewSuccess'
    ]),
    ...mapActions('closet', [
      'getReviews'
    ]),
    ...mapActions('community', [
      'getOccasionTags'
    ]),
    async completeNextReviewAction () {
      if (this.isLastReview) {
        if (this.useSingleItemReviewFlow) {
          this.submitReviewSuccess()
          this.goBack()
        } else if (this.itemsToSwap.length > 0) {
          this.$router.push({ name: 'swap-confirm' })
        } else if (this.deliveredReviewedItems.length > 0 && this.canSwapDeliveredItems) {
          this.$router.push({ name: 'review-optional-swap-select' })
        } else {
          this.$router.push({ name: 'review-success' })
        }
      } else {
        const nextReviewItemId = this.reviewItems[this.currentReviewItemIndex + 1].id
        this.$router.push({ name: 'review-item', params: { reviewItemId: nextReviewItemId } })
      }
    },
    async onConfirm () {
      this.error = null
      if (this.assertValid()) {
        this.$store.commit('review/SET_REVIEW', this.localReview)
        this.$store.commit('review/COUNT_FIVE_STAR_REVIEWS', this.localReview)
        try {
          this.submitting = true
          await this.submitReviews({
            isLastReview: this.isLastReview,
            currentReviewItemIndex: this.currentReviewItemIndex
          })
          this.submitting = false
          this.completeNextReviewAction()
        } catch (error) {
          this.error = error
          this.submitting = false
        }
      }
    },
    assertValid () {
      this.v$.$touch()
      if (this.v$.$invalid) {
        this.$nextTick(() => {
          const e = document.querySelector('.error-scroll')
          e.scrollIntoView({ block: 'start', behavior: 'smooth' })
        })
        return false
      }
      return true
    }
  },
  validations () {
    const baseLocalReview = {
      overallStarRating: {
        required
      },
      sizingRating: {
        required
      },
      nWears: {
        required
      },
      fitRating: {
        required
      },
      maternityFriendly: {
        required: (value, vm) => {
          if (!vm.maternityFriendlySectionRequired) { return true }
          return value !== null
        }
      },
      expertReview: {
        required: (value, vm) => {
          if (!vm.expertReviewSectionRequired) { return true }
          return value !== null
        }
      },
      size: {
        reviewCheckbox: (val, vm) => {
          return vm.fitRating === 'dislike' ? val.length >= 1 : true
        }
      },
      tooBig: {
        reviewCheckbox: (val, vm) => {
          return vm.size.includes('too big') ? val.length >= 1 : true
        }
      },
      tooSmall: {
        reviewCheckbox: (val, vm) => {
          return vm.size.includes('too small') ? val.length >= 1 : true
        }
      },
      qualityIssue: {
        required: (val, vm) => {
          return vm.qualityIssue === true ? val !== null : true
        }
      },
      qualityChoices: {
        reviewCheckbox: (val, vm) => {
          return vm.qualityIssue === true ? val.length >= 1 : true
        }
      },
      feedbackTextPrivacyLevel: {
        required: (val, vm) => {
          if (!vm.feedbackTextPrivacyLevelRequired) { return true }
          return val !== null
        }
      },
      itemOccasions: {
        required: (val, vm) => {
          if (!vm.occasionTagsRequired) { return true }
          return val.length >= 1
        }
      },
      styleChoices: {
        reviewCheckbox: (val, vm) => {
          return vm.styleRating === 'dislike' ? val.length >= 1 : true
        }
      }
    }

    const requiredTextReview = {
      qualityFeedbackText: {
        required,
        minLength: minLength(100)
      }
    }

    const requiredPhotoUpload = {
      itemFeedbackPhotos: {
        required: (value, vm) => {
          if (!vm.feedbackPhotosRequired) { return true }
          return value !== null
        }
      }
    }

    return {
      localReview: this.richReviewRequired
        ? this.photoUploadRequired
          ? { ...baseLocalReview, ...requiredTextReview, ...requiredPhotoUpload }
          : { ...baseLocalReview, ...requiredTextReview }
        : baseLocalReview
    }
  }
}
</script>

<style lang="scss" scoped>
@import './ReviewItem.scss';
</style>
