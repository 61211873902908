<template>
  <div
    v-if="isMobile"
    class="pt-4 mb-3">
    <action-header page-header>
      <h5>Account</h5>
    </action-header>
  </div>
  <div
    v-else
    class="container pt-4 px-0">
    <bread-crumb
      class="mb-3 mx-3"
      current-location="Notifications"
      :crumbs="[{ name: 'Account', to: { name: 'account' } }]"/>
    <h2
      class="pb-3 mx-2 mx-md-3 text-md-start">
      Notifications
    </h2>
  </div>
  <div class="container">
    <div class="row">
      <div
        class="col-md-4">
        <stacked-menu
          :menu-items="actions"/>
      </div>
      <div
        class="col-md-8"
        :class="{'mt-3' : isMobile}">
        <router-view #default="{ Component }">
          <transition
            name="fade"
            mode="out-in">
            <component :is="Component"/>
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import ActionHeader from '../../global/sequin/ActionHeader'
import BreadCrumb from '../../global/sequin/BreadCrumb'
import StackedMenu from '../../global/sequin/StackedMenu.vue'
import NotificationsMixin from './NotificationsMixin'
import { vuexAccessors } from '../../global/helpers/vuex'
import { mapState } from 'vuex'

export default {
  name: 'AccountNotifications',
  components: { ActionHeader, BreadCrumb, StackedMenu },
  mixins: [NotificationsMixin],
  data () {
    return {
    }
  },
  computed: {
    ...vuexAccessors('account', [
      'breadcrumbs'
    ]),
    ...mapState('client', [
      'settings'
    ]),
    actions () {
      const actions = [
        {
          to: 'notification-settings-membership',
          name: 'Membership',
          description: this.buildDescription(this.settings,
            ['notifyMembershipPush', 'notifyMembershipEmail']),
          selected: this.$route.name === 'notification-settings-membership'
        },
        {
          to: 'notification-settings-feed',
          name: 'The PWR Feed',
          description: this.buildDescription(this.settings,
            ['notifyPwrFeedPush', 'notifyPwrFeedEmail']),
          selected: this.$route.name === 'notification-settings-feed'
        },
        {
          to: 'notification-settings-announcements',
          name: 'Armoire Announcements',
          description: this.buildDescription(this.settings,
            ['notifyAnnouncementsPush']),
          selected: this.$route.name === 'notification-settings-announcements'
        }
      ]
      return actions
    }
  },
  mounted () {
    this.breadcrumbs = [{ name: 'Notifications' }]
  }
}
</script>
