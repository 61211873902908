<template>
  <div v-if="referralCampaign">
    <referral-banner-mobile
      v-if="isMobile"
      :header="bannerHeader"
      :referrer-credit="referrerCredit"
      :referee-bonus-items="refereeBonusItems"/>
    <referral-banner
      v-else
      :header="bannerHeader"
      :referrer-credit="referrerCredit"
      :referee-bonus-items="refereeBonusItems"/>
    <div class="container">
      <div :class="{'section': !isMobile}">
        <div class="row">
          <div
            class="col-sm"
            :class="{'section': isMobile}">
            <referral-share-link
              :referral-code="referralCode"/>
          </div>
          <div
            class="col-sm"
            :class="{'section': isMobile}">
            <referral-share-actions
              v-if="referralCampaign"
              :referral-url="`https://www.armoire.style/refer/${referralCode}`"
              :referral-campaign="referralCampaign"/>
          </div>
        </div>
      </div>
      <div class="section">
        <hr class="py-2">
        <div class="d-flex justify-content-center">
          <h4>Your Referrals + Rewards</h4>
        </div>
        <referral-reward-summary
          v-if="referrals && referrals.length"
          :credit-available="creditAvailable"
          :referrals="referrals"
          :referral-awards="referralAwards"/>
        <div
          v-else
          class="d-flex justify-content-center text-center">
          <div class="mt-4">
            <h5>You haven't earned any rewards (yet)!</h5>
            <p>
              When a friend signs up using your link, we'll send you a notification and display your reward balance here.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="referrals && referrals.length"
      class="mb-4">
      <referral-history-mobile
        v-if="isMobile"
        class="section"
        :referrals="referrals"/>
      <referral-history
        v-else
        class="section"
        :referrals="referrals"/>
    </div>
  </div>
</template>

<script>
import ReferralBanner from './ReferralBanner'
import ReferralRewardSummary from './ReferralRewardSummary'
import ReferralHistory from './ReferralHistory'
import ReferralShareLink from './ReferralShareLink'
import ReferralShareActions from './ReferralShareActions'
import ReferralBannerMobile from './ReferralBannerMobile'
import ReferralHistoryMobile from './ReferralHistoryMobile'
import { mapActions, mapState, mapGetters } from 'vuex'
import { track } from '../../plugins/analytics'

export default {
  components: {
    ReferralHistoryMobile,
    ReferralBannerMobile,
    ReferralHistory,
    ReferralRewardSummary,
    ReferralBanner,
    ReferralShareLink,
    ReferralShareActions
  },
  computed: {
    ...mapState('client', [
      'referralCode',
      'referrals',
      'referralAwards',
      'balance'
    ]),
    ...mapState('subscribe', [
      'referralCampaign'
    ]),
    ...mapGetters('subscribe', [
      'bestReferralPlan',
      'refereeDiscount',
      'refereeBonusItems'
    ]),
    referrerCredit () {
      const { referrerCredit } = this.referralCampaign || {}
      return (referrerCredit || 0) / 100
    },
    bannerHeader () {
      const { referrerCredit } = this.referralCampaign || {}
      let bonusItemsText = ''
      if (this.refereeBonusItems) {
        bonusItemsText = ` and ${this.refereeBonusItems} free bonus item`
        if (this.refereeBonusItems > 1) { bonusItemsText += 's' }
      }
      return `You'll get $${(referrerCredit || 0) / 100}. She'll get our best
      deal: Up to ${this.refereeDiscount}% off her first month of Armoire${bonusItemsText}!*`
    },
    bannerDetails () {
      const { referrerCredit } = this.referralCampaign || {}
      return `Share Armoire with your friends and network! As a thank you, you'll get $${(referrerCredit || 0) / 100} subscription credit for
      every friend who signs up using your link or code.`
    },
    creditAvailable () {
      // Stripe returns user credit as a negative amount, so flip the sign
      return this.balance < 0 ? -this.balance : 0
    }
  },
  mounted () {
    this.getReferrals()
    this.getReferralCampaign()
    this.getBilling()
    track('Viewed Refer Friends')
  },
  methods: {
    ...mapActions('client', [
      'getReferrals',
      'getBilling'
    ]),
    ...mapActions('subscribe', [
      'getReferralCampaign'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .section {
    margin-top: 36px;
  }
</style>
