import axios from './config'
import store from '../index'
import { serializeParams } from './helpers/helpers'
import logger from '@/logger'

// AUTH
const apiCloset = {
  getPriceRange (data) {
    return axios.get(
      '/api/stylecolors/' + data.styleColorId + '/price_range/?sizes=' + data.sizes)
  },
  getFilteredReviews (styleColorId, tagIds) {
    const url = `/api/itemfeedbacks/?version=2&style_color=${styleColorId}&otagId=`
    let tags = ''
    tagIds.forEach((tagId) => {
      if (tags === '') {
        tags = `${tagId}`
      } else {
        tags += `,${tagId}`
      }
    })
    return axios.get(`${url}${tags}`)
  },
  getReviews (styleColorId, sort = 'similarity') {
    return axios.get(`/api/itemfeedbacks/?version=2&style_color=${styleColorId}&sort=${sort}&sibling_style_colors=true`)
  },
  getSections (location) {
    const username = store.state.client.username
    const params = { version: 'redesign', location }
    return axios.get(`/api/clients/${username}/style_color_sections/`, { params })
  },
  getSection (id) {
    return axios.get(`/api/stylecolorsections/${id}/`)
  },
  getStyleColor (id) {
    return axios.get(`/api/stylecolors/${id}/`)
  },
  getClientSizingForStyleColor (id) {
    return axios.get(`/api/stylecolors/${id}/client_sizing/`)
  },
  getNewlyAvailableStyleColors () {
    const url = '/api/stylecolors/?newly_available=true'
    return axios.get(url)
  },
  getBrowseSectionsNextPage (nextPage) {
    return axios.get(nextPage)
  },
  getStyleColorsByBrowseSection (params) {
    const section = params.section
    let subsection = params.subsection
    if (section === 'occasions') {
      subsection = 'occasion_' + subsection
    }
    const availableValue = params.available ? 'true' : ''
    const available = `available=${availableValue}`
    const sort = `sort=${params.sort}`
    const filters = params.filters ? serializeParams(params.filters) : {}
    const url = params.filterOptions ? '/api/stylecolors/?filter_options=true&' : '/api/stylecolors/?'
    if (params.section === 'new') {
      if (params.filters) {
        return axios.get(`${url}newly_available=true&${available}&${sort}&${filters}`)
      } else {
        return axios.get(`${url}newly_available=true&${available}&${sort}`)
      }
    } else {
      if (params.filters) {
        return axios.get(`${url}${section}=${subsection}&${available}&${sort}&${filters}`)
      } else {
        return axios.get(`${url}${section}=${subsection}&${available}&${sort}`)
      }
    }
  },
  getBrowseSectionFilterOptions (params) {
    return this.getStyleColorsByBrowseSection({ ...params, filterOptions: true })
  },
  getSectionStyleColors (data) {
    let params = `?offset=${data.offset}&amount=${data.amount}&filter_version=2`
    if (data.available === true) params += `&available=${data.available}`
    if (data.sort) params += `&sort=${data.sort}`
    if ('sectionFilters' in data) params += '&' + serializeParams(data.sectionFilters)
    if ('sizeFilter' in data) params += `&sizeFilter=${data.sizeFilter}`
    if (typeof data.dedupeKey === 'string') params += `&dedupeKey=${data.dedupeKey}`
    return axios.get(`/api/stylecolorsections/${data.id}/style_colors/${params}`)
  },
  requestStylist (formData) {
    const username = store.state.client.username
    return axios.post('/api/clients/' + username + '/request_stylist/', formData)
  },
  getRatingsMap () {
    const username = store.state.client.username
    return axios.get('/api/clients/' + username + '/ratings_map/')
  },
  getSelected () {
    const username = store.state.client.username
    return axios.get('/api/clients/' + username + '/selected/')
  },
  getPackageItems () {
    const username = store.state.client.username
    return axios.get('/api/clients/' + username + '/package_items/')
  },
  removePackageItemsFromReturning (data) {
    return axios.post('/api/packageitems/unmark_returning/', data)
  },
  setClosetItemSelected (formData) {
    logger.info(formData)
    const username = store.state.client.username
    return axios.post('/api/clients/' + username + '/set_closet_item_selected/', formData)
  },
  submitCloset (formData) {
    const username = store.state.client.username
    return axios.post('/api/clients/' + username + '/submit_closet/', formData)
  },
  getLooksByStyleColor (styleColorId) {
    return axios.get(`/api/looks/?style_color=${styleColorId}`)
  },
  getSimilarStyleColors (styleColorId) {
    return axios.get(`/api/stylecolors/${styleColorId}/similar/`)
  },
  getAdditionalColors (styleColorId) {
    return axios.get(`/api/stylecolors/${styleColorId}/additional_colors/`)
  },
  rateReview (itemFeedbackId, rating) {
    return axios.post(`/api/itemfeedbacks/${itemFeedbackId}/rating/`, { rating })
  },
  getShippingOptions (types) {
    return axios.get(`/api/shippingrates/?types=${types}`)
  },
  getGlobalClosetFilterOptions () {
    return axios.get('/api/closets/filters/')
  },
  recordClick (styleColorId, data) {
    return axios.post(`/api/stylecolors/${styleColorId}/click/`, data)
  }
}

export default apiCloset
